import React, { useState, useEffect } from 'react';

import CargaSpinner from '../components/AccionesPartes/CargaSpinner';

import { Snackbar, Alert, TextField, MenuItem } from '@mui/material';

import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';

import './Cristian.css';

function Cristian() {

    const [contadorSpinner, setContadorSpinner] = React.useState(false);
    let hoteles = localStorage.getItem('hoteles');
    //hoteles.unshift({"value":"0","label":""});
    const [hotelesLista, SetHotelesLista] = useState([]);
    const [hotelTrabajo, SetHotelTrabajo] = useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [mensaje, setMensaje] = React.useState('');
    const [severidad, setSeveridad] = React.useState('success');

    const [actualizo, SetActualizo] = useState(true);
    const [estadoInicial, setEstadoInicial] = React.useState(true);

    const [dataTree, SetDataTree] = useState([]);

    const [selectedItem, setSelectedItem] = React.useState(null);
    const [selectedItemIdLugar, setSelectedItemIdLugar] = React.useState(null);

    const apiRef = useTreeViewApiRef();

    const actualizar = () => {
        SetActualizo(!actualizo);
    }

    const ocultarAlerta = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };
    const cambiaHotelTrabajo = (event) => {
        if (event.target.name === 'id_hotel') {
            SetHotelTrabajo(event.target.value);
        }
    }
    const mostrarError = (mensajeError) => {
        setMensaje(" " + mensajeError);
        setSeveridad("error");
        setOpenAlert(true);
    };
    const fd = new FormData();
    const requestOptions = {
        method: 'POST',
        body: fd
    };

    useEffect(() => {


        // hoteles.unshift({"value":"0","label":""});
        let listaHoteles = JSON.parse(hoteles);
        //listaHoteles.unshift({ "value": "", "label": "" });
        SetHotelesLista(listaHoteles);

        //Si tenemos un hotel guardado en el localStorage utilizando Ajustes.
        let idHotel = localStorage.getItem('idHotelSeleccionado');
        if (estadoInicial) {
            if (idHotel) {
                SetHotelTrabajo(idHotel);
                setEstadoInicial(false);
            } else {
                console.log(listaHoteles);
            }
        }
        if (hotelTrabajo !== 0) {
            fd.append("f", "partes.getLocationsTree");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_hotel", hotelTrabajo);
            fetch('https://api.grupotel.com/workers.2/', requestOptions)
                .then(response => response.json(), setContadorSpinner(true))
                .then(result => {
                    let error = result.error;
                    SetDataTree(result)
                    setContadorSpinner(false)
                    handleExpandClick()
                    // if (error.id === 0) {
                    //     console.log(result);
                    //     delete result.error;

                    //     const timer2 = setTimeout(() => {
                    //     }, 500);

                    // } else if (error.id >= 501 && error.id <= 510) {
                    //     localStorage.setItem('token', 'CADUCADO');
                    //     window.location.reload();
                    // } else {
                    //     mostrarError(error.msg)
                    // }
                })
                .catch(() => {
                    mostrarError("No se ha podido obtener las habitaciones.")
                })
        }
    }, [hotelTrabajo, actualizo]);

    const handleSelectTreeItem = (event, itemId) => {
        if (itemId == null) {
            setSelectedItem(null);
            setSelectedItemIdLugar(null);
        } else {
            setSelectedItem(apiRef.current.getItem(itemId));
            setSelectedItemIdLugar(getItemId(apiRef.current.getItem(itemId)))
        }
    };
    function getItemId(item) {
        return item.id_lugar;
    }
    const handleExpandClick = () => {
        setExpandedItems((oldExpanded) =>
            oldExpanded.length = []
        );
    };
    const handleExpandedItemsChange = (event, itemIds) => {
        setExpandedItems(itemIds);
    };
    const [expandedItems, setExpandedItems] = React.useState([]);

    // const MUI_X_PRODUCTS = [
    // ]


    return (
        <>
            <div className='container'>
                <div className='content' style={{ "height": "70px" }}>
                    <div className='article'>
                        <div className='header'>
                            <div className='containerAcciones contenidoIzquierda'>
                                <TextField
                                    className='fifty'
                                    variant="outlined"
                                    margin="normal"
                                    label="Hotel"
                                    name="id_hotel"
                                    size="small"

                                    value={hotelTrabajo ? hotelTrabajo : ""}
                                    onChange={cambiaHotelTrabajo}
                                    select
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                            }
                                        }
                                    }}
                                >
                                    {hotelesLista.map((hotel) => (
                                        <MenuItem value={hotel.value} >
                                            {hotel.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        {dataTree.length > 0 ?
                            <RichTreeView
                                expandedItems={expandedItems}
                                selectedItems={selectedItem?.id ?? null}
                                onSelectedItemsChange={handleSelectTreeItem}
                                onExpandedItemsChange={handleExpandedItemsChange}
                                apiRef={apiRef}
                                items={dataTree}>
                            </RichTreeView >
                            :
                            'Sin arbol'}
                    </div>
                    <div className='aside' >
                        <div className='header'>
                            <p sx={{ minWidth: 300 }}>
                                Selected item: {selectedItem == null ? 'none' : selectedItem.label}
                            </p>
                            <p sx={{ minWidth: 300 }}>
                                {selectedItem == null ? 'none' : selectedItem.id_lugar}
                            </p>
                        </div>
                    </div>
                </div>

                <CargaSpinner contadorSpinner={contadorSpinner} />
                <Snackbar open={openAlert} autoHideDuration={3000} onClose={ocultarAlerta}>
                    <Alert onClose={ocultarAlerta} severity={severidad} sx={{ width: '100%' }}>
                        {mensaje}
                    </Alert>
                </Snackbar>
            </div>
        </>
    )
}
export default Cristian;
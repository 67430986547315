import React, { useState, useEffect } from 'react';
import { Typography, Button  } from '@mui/material';
import MostrarError from '../../components/AccionesPartes/MostrarError';
import { Link } from 'react-router-dom';


import './Home.css';

function Home() {

    const [info, setInfo] = useState(null);

    const fd = new FormData();

    const requestOptions = {
        method: 'POST',
        body: fd
    };

    window.history.replaceState({}, document.title, '/#/Home');
    // if(window.location.href.includes('10.100.10.210')){
    //     window.history.replaceState({}, document.title, '/');
    //     // console.log(first)
    // }else if (window.location.href.includes('workers.grupotel.com')){
    //     // console.log(first)

    // }

    useEffect(() => {
        fd.append("f", "partes.getCantidadPartes");
        fd.append("token", localStorage.getItem('token'));
        fetch('https://api.grupotel.com/workers.2/', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setInfo(data);
                //console.log(data);
            })
            .catch((error) => {
                MostrarError('Error al obtener la información de la API', error);
            });
    }, []);

    return (
        <div>
            <div className='header'>
                <h1 className='centrar'>HOME</h1>
            </div>


            <div className='homeContainer'>
                <div className='homeContainerHeader'>

                    <Typography variant="h5" component="div" className='centrar'>
                        Información Partes
                    </Typography>
                </div>

                {info !== null ? (
                    <div className='containerButtonContainer'>
                        <Link to="/Partes/Entradas/nuevo" className='enlaces-button'>
                            <Button className="nuevo-button custom-button">
                                <span className="custom-button-text">{info.nuevos}</span>
                                <span className="custom-button-label">Nuevos</span>
                            </Button>
                        </Link>
                        <Link to="/Partes/Entradas/ejecucion" className='enlaces-button'>
                            <Button className="en-ejecucion-button custom-button">
                                <span className="custom-button-text">{info.ejecucion}</span>
                                <span className="custom-button-label">Ejecución</span>
                            </Button>
                        </Link>
                        <Link to="/Partes/Entradas/terceros" className='enlaces-button'>
                            <Button className="terceros-button custom-button">
                                <span className="custom-button-text">{info.terceros}</span>
                                <span className="custom-button-label">Terceros</span>
                            </Button>
                        </Link>
                        <Link to="/Partes/Entradas/aplazados" className='enlaces-button'>
                            <Button className="nuevo-button custom-button">
                                <span className="custom-button-text">{info.aplazados}</span>
                                <span className="custom-button-label">Aplazados</span>
                            </Button>
                        </Link>
                    </div>
                ) : null}
            </div>
        </div>
    );
}


export default Home;
import React, { useState, useEffect } from 'react';

import './Maquinas.css';

import { DataGrid } from '@mui/x-data-grid';
import {
  IconButton, ButtonGroup, Tooltip, MenuItem, tooltipClasses, Typography, styled, Zoom, Button, TextField, Dialog, DialogActions,
  DialogContent, DialogTitle, useMediaQuery, Checkbox, FormControlLabel, List, ListItem, ListItemButton, ListItemText, Box, Autocomplete,
  Alert, Snackbar, Badge, Tab, Tabs, Collapse
} from '@mui/material';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import PrintIcon from '@mui/icons-material/Print';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';

import CancelIcon from '@mui/icons-material/Cancel';

import { useTheme } from '@mui/material/styles';
import MostrarError from '../../components/AccionesPartes/MostrarError';
import CargaSpinner from '../../components/AccionesPartes/CargaSpinner';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(10),
    border: '1px solid #dadde9',

  },
}));

const columnas = [
  {
    headerClassName: 'header-grid', field: 'id', headerName: 'ID', width: 80,
  },
  {
    headerClassName: 'header-grid', field: 'nombre', headerName: 'Nombre', width: 500,
  },
  {
    headerClassName: 'header-grid', field: 'n_serie', headerName: 'Num Serie', width: 160,
  },
  {
    headerClassName: 'header-grid', field: 'grupo', headerName: 'Grupo', width: 200,
  },
  {
    headerClassName: 'header-grid', field: 'hotel', headerName: 'Hotel', width: 160,
  },
  {
    headerClassName: 'header-grid', field: 'departamento', headerName: 'Departamento', width: 160,
  },
  {
    headerClassName: 'header-grid', field: 'activo', headerName: 'Activo', width: 80,
  },
  {
    headerClassName: 'header-grid',
    field: 'borrar',
    headerName: 'Borrar',
    width: 80,
    renderCell: (params) => {
      const onClickBorrar = () => handleBorrar(params.row.id);
      return (
        <HtmlTooltip
          placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
          title={
            <React.Fragment>
              <Typography color="inherit">{"Borrar"}</Typography>
            </React.Fragment>
          }
        >
          <Button
            className="nuevoParte"
            onClick={onClickBorrar}
          >
            <DeleteIcon className='icon-button' />
          </Button>
        </HtmlTooltip>
      );
    },
  }
]

// Función para manejar el clic en el botón de "Borrar"
const handleBorrar = (id) => {
  // Aquí puedes implementar la lógica para borrar la fila con el ID proporcionado
  console.log(`Borrar fila con ID: ${id}`);
};

function Maquinas() {
  const API_URL = 'https://api.grupotel.com/workers.2/';
  const Api_Maquinas_Img = 'https://api.grupotel.com/workers.2/imagenes/maquinas/';
  const Api_Maquinas_Antiguo_Img = 'https://api.grupotel.com/workers/imagenes/maquinas/';

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  let hoteles = localStorage.getItem('hoteles');

  const [hotelesLista, SetHotelesLista] = useState([]);
  const [hotelTrabajo, SetHotelTrabajo] = useState(0);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [severidad, setSeveridad] = React.useState('success');

  const [estadoInicial, setEstadoInicial] = React.useState(true);

  const [actualizo, SetActualizo] = useState(true);

  //Boolean para mostrar o ocultar el spinner de carga
  const [contadorSpinner, setContadorSpinner] = React.useState(false);

  //Pruebas para controlar el Focus en DataGrid
  const [filaSeleccionada, setFilaSeleccionada] = useState(null);

  const [listaMaquinas, setListaMaquinas] = useState([]);
  const [nuevaMaquina, setNuevaMaquina] = React.useState(false);
  const [mostrarMaquina, setMostrarMaquina] = React.useState(false);
  // Controlador del Dialog para borrar la imagen de una maquina
  const [estadoBorrarImagen, setEstadoBorrarImagen] = React.useState(false);
  const [imagenMaquina, setImagenMaquina] = useState(0);


  const [btnActivo, setBtnActivo] = React.useState(false);
  const [estadoFiltros, setEstadoFiltros] = React.useState(false);
  const [activoFiltro, setActivoFiltro] = useState(false);
  const [gruposLista, setGruposLista] = useState([]);
  const [proveedoresLista, setProveedoresLista] = useState([]);
  const [departamentosLista, setDepartamentosLista] = useState([]);

  //Tabs del Dialog de Mostrar informacion de la maquina
  const [tabValue, setTabValue] = useState(0); // Estado para la pestaña activa
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  //Conjunto para mostrar un Alert con el resultado de la petición
  const [mensajeError, setMensajeError] = useState('');
  const [tipoError, setTipoError] = useState('');
  const [contadorError, setContadorError] = useState(1);
  const [estadoAlert, setEstadoAlert] = React.useState(false);

  //Funcion basica para mostrar alert de errores o success.
  const mostrarError = (mensajeError, tipoError) => {
    setMensajeError(mensajeError);
    if (tipoError) {
      setTipoError(tipoError);
    } else {
      setTipoError("error");
    }
    setContadorError(contadorError + 1);
    setEstadoAlert(true);
    const timer2 = setTimeout(() => {
      setEstadoAlert(false);
    }, 3000);
    return () => clearTimeout(timer2);
  };

  const ocultarAlerta = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const cambiaHotelTrabajo = (event) => {
    if (event.target.name === 'id_hotel') {
      SetHotelTrabajo(event.target.value);
    }
  }

  const actualizar = () => {
    SetActualizo(!actualizo);
  }

  const fd = new FormData();
  const requestOptions = {
    method: 'POST',
    body: fd
  };

  const [datosFiltroMaquina, setDatosFiltroMaquina] = useState({
    id: '',
    nombre: '',
    n_serie: '',
    grupo: -1,
    activo: false
  });

  const [datosInfoMaquina, setDatosInfoMaquina] = useState({
    id: '',
    id_departamento: '',
    id_grupo: '',
    id_hotel: '',
    id_lugar: '',
    n_serie: '',
    modelo: '',
    nombre: '',
    cif_instalador: '',
    cif_mantenedor: '',
    fabricante: '',
    fecha_instalacion: '',
    anyo_fabricacion: '',
    alto: '',
    ancho: '',
    largo: '',
    comentarios: '',
    activo: false
  });
  //Copia de la maquina para evitar que hagan muchas solicitudes de actualizar maquina sin ningun cambio.
  const [copiaInfoMaquina, setCopiaInfoMaquina] = useState({
    id: '',
    id_departamento: '',
    id_grupo: '',
    id_hotel: '',
    id_lugar: '',
    n_serie: '',
    modelo: '',
    nombre: '',
    cif_instalador: '',
    cif_mantenedor: '',
    fabricante: '',
    fecha_instalacion: '',
    anyo_fabricacion: '',
    alto: '',
    ancho: '',
    largo: '',
    comentarios: '',
    activo: false
  });

  //Nuevas imagenes
  const [listaNuevasImagenMaquina, setListaNuevasImagenMaquina] = useState([]);

  //Imagenes de la maquina seleccionada
  const [listaImagenesMaquina, setListaImagenesMaquina] = useState([]);

  const [listaPartesMaquina, setListaPartesMaquina] = useState([]);
  //Listado de Fotos del parte
  const [listaFotosParte, setListaFotosParte] = useState([]);
  //Listado de PDF del parte
  const [listaPdfParte, setListaPdfParte] = useState([]);


  const getMaquinas = () => {
    let maquinas = [];
    const datos = datosFiltroMaquina;
    let params = '';

    Object.keys(datos).forEach((nombre, i) => {
      const valor = Object.values(datos)[i];
      if (valor && valor !== '') {
        if (nombre === 'nombre') {
          params += `${nombre}='${JSON.stringify(valor)}'|`;
        } else if (nombre === 'grupo') {
          const valorBloque = parseInt(valor);
          if (!isNaN(valorBloque)) {
            params += `${nombre}=${valor}|`;
          } else {
            mostrarError("El grupo debe ser un ID válido");
            return;
          }
        } else {
          params += `${nombre}=${valor}|`;
        }
      }
    });
    if (btnActivo === true) {//Si esta true
      params += 'activo=1|';
    }

    fd.append("f", "maquinas.getMaquinas");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_hotel", hotelTrabajo);
    fd.append("params", params);

    fetch(API_URL, requestOptions)
      .then(response => response.json(), setContadorSpinner(true))
      .then(result => {
        let error = result.error;
        const handleResponse = () => {
          setContadorSpinner(false);
          if (error.id === 0) {
            delete result.error;
            const InfoMaquinas = result.maquinas;
            Object.values(InfoMaquinas).forEach(maquina => {
              const parteObj = {};
              Object.entries(maquina).forEach(([key, value]) => {
                parteObj[key] = value;
              });

              maquinas.push(parteObj);
            });
            setListaMaquinas(maquinas);
          }else if (error.id >= 501 && error.id <= 510) {
            localStorage.setItem('token', 'CADUCADO');
            window.location.reload();
          } else {
            setListaMaquinas('');
            mostrarError(error.msg);
          }
        };

        setTimeout(handleResponse, 500);
      })
      .catch(() => {
        mostrarError("No se ha podido obtener las Máquinas");
      });
  };

  /******************************************************************/
  //                   Inicio  Dialog Info Maquina                  //
  /******************************************************************/

  ///////////////////////////////////////////////
  //  Inicio  SUB Apartado Información Maquina //
  ///////////////////////////////////////////////

  const getInfoMaquina = (id_maquina) => {
    const fd = new FormData();
    fd.append("f", "maquinas.getInfoMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id_maquina);
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          // console.log("La información de la maquina: ")
          // console.log(result);
          let fechaInstalacion = result.maquina.fecha_instalacion ? result.maquina.fecha_instalacion : ''; // Si es nula o vacía, asignar una cadena vacía

          setDatosInfoMaquina(result.maquina);
          setCopiaInfoMaquina(result.maquina);

          if (!fechaInstalacion || fechaInstalacion.trim() === "" || fechaInstalacion === null || fechaInstalacion === "0000-00-00 00:00:00") {
            setDatosInfoMaquina(prevState => ({
              ...prevState,
              fecha_instalacion: ''
            }));
            setCopiaInfoMaquina(prevState => ({
              ...prevState,
              fecha_instalacion: ''
            }));
          } else {
            let soloFecha = fechaInstalacion.split(" ");
            fechaInstalacion = soloFecha[0];
            setDatosInfoMaquina(prevState => ({
              ...prevState,
              fecha_instalacion: fechaInstalacion
            }));
            setCopiaInfoMaquina(prevState => ({
              ...prevState,
              fecha_instalacion: fechaInstalacion
            }));
          }

        } else {
          //vaciar datosMaquina
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener la informacion de la maquina: " + id_maquina);
      })
  };

  //Falta añadir tema de caracteristicas, modificación de Lugar.

  ///////////////////////////////////////////////
  //   Fin  SUB Apartado Información Maquina   //
  ///////////////////////////////////////////////

  ///////////////////////////////////////////////
  //  Inicio  SUB Apartado Imagenes / Fotos    //
  ///////////////////////////////////////////////

  //Obtener las fotos de la maquina: para conseguir mantener la ubicación en el tab sin recargar todo, no añado esta función al getInfoMaquina
  const getFotosMaquina = (id_maquina) => {
    const fd = new FormData();
    fd.append("f", "maquinas.getFotosMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id_maquina);
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          // console.log("Las imagenes son: ")
          // console.log(result.fotos);
          setListaImagenesMaquina(result.fotos);
        } else {
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener las imagenes");
      })
  };

  const ventanaImagen = (ruta) => {
    const rutaMostrar = ruta.includes("min_") ? ruta.replace("min_", "") : ruta;
    window.open(rutaMostrar);
  };

  const selecImage = (event) => {
    const files = event.target.files;
    if (files.length > 0 && files.length <= 3) {
      // Creamos un array para almacenar las nuevas imágenes seleccionadas
      let nuevasImagenes = [];

      // Iteramos sobre los archivos seleccionados y los agregamos al array
      Array.from(files).forEach((file, index) => {
        nuevasImagenes.push({
          id: index,
          ruta: URL.createObjectURL(file),
          nombre: file.name,
          archivo: file
        });
      });

      // Actualizamos el estado con las nuevas imágenes seleccionadas
      setListaNuevasImagenMaquina(nuevasImagenes);
    } else if (files.length > 3) {
      mostrarError("Máximo 3 imagenes");
      console.log("Máximo 3 imagenes")
      setListaNuevasImagenMaquina([]);
      event.target.value = null;  // Limpiar el input

    } else {
      console.log("Máximo 3 imagenes")
      setListaNuevasImagenMaquina([]);
      event.target.value = null;  // Limpiar el input
    }
  };

  //Recibimos el id/ index realmente de la foto seleccionada.
  const borrarImagen = (id) => {
    setListaNuevasImagenMaquina(listaNuevasImagenMaquina.filter((foto) => foto.id !== id));
  };

  //Función para subir las imagenes de una maquina.
  const setImagenesMaquina = () => {

    if (listaNuevasImagenMaquina.length >= 1) {

      const fd = new FormData();
      fd.append("f", "maquinas.setImagenesMaquina");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id", datosInfoMaquina.id);

      //Recorremos las nuevas imagenes
      listaNuevasImagenMaquina.forEach((imagen, i) => {
        fd.append("imagen" + i, imagen.archivo);
      });
      const requestOptions = {
        method: 'POST',
        body: fd
      };

      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          if (error.id === 0) {
            setListaNuevasImagenMaquina([]);
            mostrarError("Maquina: " + datosInfoMaquina.id + " imagen actualizada", "success");
            setMostrarMaquina(false);
            actualizar();
          } else {
            mostrarError(error.msg);
          }
        })

        .catch(() => {
          mostrarError("No se ha podido obtener los proveedores");
        })
    } else {
      setMostrarMaquina(false);
      actualizar();
    }
  }

  //Función para borrar una imagen de la maquina
  const delImgMaquina = () => {

    //Borrar la relación en la bbdd
    const fd = new FormData();
    fd.append("f", "maquinas.delFotoMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_foto", imagenMaquina);
    const requestOptions = {
      method: 'POST',
      body: fd
    };
    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          setImagenMaquina(0);
          setEstadoBorrarImagen(false);
          getFotosMaquina(datosInfoMaquina.id);
        } else {
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener los proveedores");
      })
  }

  //Función para mostrar el dialog, espera confirmación para borrar la imagen.
  const confirmDelImgMaquina = (id_foto) => {
    setImagenMaquina(id_foto);
    setEstadoBorrarImagen(true);
  }
  const cancelDelImgMaquina = () => {
    //En caso de cancelar y no querer borrar la imagen.
    setImagenMaquina(0);
    setEstadoBorrarImagen(false);
  }

  ///////////////////////////////////////////////
  //    Fin  SUB Apartado Imagenes / Fotos     //
  ///////////////////////////////////////////////

  ///////////////////////////////////////////////
  //    Inicio  SUB Apartado Partes Maquina    //
  ///////////////////////////////////////////////

  //Obtención de Los Partes de la Maquina.
  const listarTodosPartesHabitacion = (id_maquina) => {

    //Peticion para obtener todos los partes de la habitacion 
    fd.append("f", "partes.getByMaquina");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_maquina", id_maquina);
    fd.append("id_hotel", hotelTrabajo);

    fetch(API_URL, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((result) => {

        let error = result.error;
        if (error.id === 0) {
          delete result.error;
          setListaPartesMaquina(result.partes);

        } else {
          setListaPartesMaquina('');
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("Error Api. Obtener los partes de una habitacion.");
      })

  };

  //Boolean para mostrar los historicos del parte que le indiquemos.
  const [isOpenCollapse, setIsOpenCollapse] = useState(null);
  const abrirHistorial = (clickedIndex) => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  //Parte seleccionado
  const [idParte, setIdParte] = useState('');
  //Boolean para mostrar o cultar el dialog que contendrá las imagenes del parte seleccionado.
  const [galeriaImagenes, setGaleriaImagenes] = React.useState(false);
  //Posicion en la lista de Imagenes
  const [posImage, setPosImage] = useState(0);

  //Boolean para mostrar o ocultar el dialog de los PDF.
  const [galeriaPdf, setGaleriaPdf] = React.useState(false);

  const verInforme = (id_parte) => {
    let url = "https://api.grupotel.com/workers/index.php?token=workers2&action=getPartePdf&id=";
    let final_url = url + id_parte;
    window.open(final_url, '_blank', 'noreferrer');
  }

  const abrirImagen = (fotos, id_parte) => {
    setListaFotosParte(fotos);
    setIdParte(id_parte);
    setGaleriaImagenes(true);
  }

  const ocultarGaleria = () => {
    setGaleriaImagenes(false);
    setPosImage(0);
  };
  const abrirPdf = (pdf, id_parte) => {
    //Guardamos el Listado de los Pdf
    setListaPdfParte(pdf);
    setIdParte(id_parte);
    setGaleriaPdf(true);
  }
  const ocultarPdf = () => {
    setGaleriaPdf(false);
  }
  const mostrarPdf = (pdf) => {
    //Abrimos la imagen en una nueva ventana.
    window.open("https://api.grupotel.com/workers.2/imagenes/parte/" + idParte + "/" + pdf);
  }
  //Funcion para pasar a la imagen anterior
  const prevImagen = () => {
    if (posImage - 1 === -1) {
      setPosImage(listaFotosParte.length - 1);
    } else {
      setPosImage(posImage - 1);
    }
  };

  //Funcion para pasar a la siguiente imagen
  const nextImagen = () => {
    if (posImage + 1 === listaFotosParte.length) {
      setPosImage(0);
    } else {
      setPosImage(posImage + 1);
    }
  };
  const abrirVentanaImagen = () => {
    //Obtenemos la información de la imagen que estamos mostrando. Utilizando el ID.
    var imagen = document.getElementById('imagenGaleria');
    //Abrimos la imagen en una nueva ventana.
    window.open(imagen.src);
  };

  const listaHistoricos = (fotos, parteId) => {
    let pdfHistorico = [];
    let fotoHistorico = [];

    if (fotos) {
      fotos.map((foto, j) => {
        let extension = foto.archivo.split(".");
        if (extension[extension.length - 1] === "pdf") {//Caso de que la extension es un pdf
          pdfHistorico.push(foto);
        } else {//Si no es un pdf entonces sera una imagen.
          fotoHistorico.push(foto);
        }
        return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
      })
      return <><div className=' divImagen'>
        {pdfHistorico.length >= 1 ? <IconButton className='boton ' onClick={(e) => { e.stopPropagation(); abrirPdf(pdfHistorico, parteId) }} >
          <PictureAsPdfIcon className='icon-button ' />
        </IconButton> : ""}
      </div>
        <div className=' divImagen'>
          {fotoHistorico.length >= 1 ? <IconButton className='boton ' onClick={(e) => { e.stopPropagation(); abrirImagen(fotoHistorico, parteId) }} >
            <ImageSearchIcon className='icon-button ' />
          </IconButton> : ""}
        </div>
      </>
    }
  }

  //Ordenamos por el index utilizando el sort. Ya que la respuesta del fetch al pasar a json nos ordena el resultado por el id de Parte.
  const listaPartes = Object.values(listaPartesMaquina).sort((a, b) => (a.index > b.index) ? 1 : -1).map((parte, i) =>
    <>
      <ListItemButton onClick={() => abrirHistorial(parte.id)} key={parte.id} id={parte.id} className={parte.operacion === 'en ejecucion' ? 'en-ejecucion bordeParte infoParte' : parte.operacion + "-gradient bordeParte infoParte"}>
        <HtmlTooltip
          placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
          title={
            <React.Fragment>
              <Typography color="inherit" onClick={(e) => { e.stopPropagation() }}>{parte.id}</Typography>
            </React.Fragment>
          }
        >
          <ListItemText className='infoParteId' >
            |
          </ListItemText>
        </HtmlTooltip>
        <ListItemText className='infoParteRoom infoTitulo' primary={parte.titulo} />
        <ListItemText className='infoParteRoom infoHistorico' primary={parte.fecha} />
        <div className='infoParteRoom centrarInfoParteRoom usuarioImagen' >

          <ListItemText primary={parte.creador} onClick={() => abrirHistorial(parte.id)} />
          {parte.fotos ?
            listaHistoricos(parte.fotos, parte.id)
            : ""
          }
          <div className=' divImagen'>
            <IconButton className='boton ' onClick={(e) => { e.stopPropagation(); verInforme(parte.id) }} >
              <PrintIcon className='icon-button ' />
            </IconButton>
          </div>
        </div>



      </ListItemButton>
      <Collapse in={isOpenCollapse === parte.id} timeout="auto" unmountOnExit className='separarPalabras'>
        {parte.historico ?
          < div >
            {parte.historico.map((historico, j) => {
              return < div className={historico.estado === 'en ejecucion' ? 'en-ejecucion  infoParteIdHijo' : historico.estado + "-gradient infoParteIdHijo"}>
                <ListItem >
                  <HtmlTooltip
                    placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{historico.estado}</Typography>
                      </React.Fragment>
                    }
                  >
                    <ListItemText className='infoParteId'>
                      |
                    </ListItemText>
                  </HtmlTooltip>

                  <ListItemText className='mostrar justificarTexto infoParteRoom infoTitulo' >
                    {historico.fecha_aplazado !== '' ?
                      historico.estado === 'terceros' ?
                        '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
                        : '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + historico.observaciones

                      : historico.estado === 'terceros' ?
                        '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
                        : historico.observaciones
                    }
                  </ListItemText>
                  <ListItemText className='mostrar justificarTexto infoParteRoom infoHistorico' >
                    {historico.fecha_evento}
                  </ListItemText>
                  <div className='infoParteRoom usuarioHistorico' >
                    {historico.usuario_asignado}
                  </div>
                </ListItem>
              </div>
            })
            }</div>
          : ""
        }
      </Collapse>
    </>
  );





  ///////////////////////////////////////////////
  //     Fin  SUB Apartado Partes Maquina      //
  ///////////////////////////////////////////////


  //Obtenemos los proveedores y montamos la estructura del 
  const getInfoProveedores = () => {
    const fd = new FormData();
    fd.append("f", "proveedores.getCifList");
    fd.append("token", localStorage.getItem('token'));
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          const proveedores = Object.entries(result.proveedores).map(([key, value]) => ({
            cif: value.cif,
            nombre_comercial: value.nombre_comercial,
            label: `${value.cif} - ${value.nombre_comercial}`
          }));

          // Ordenar por nombre_comercial y luego por cif
          proveedores.sort((a, b) => {
            if (a.nombre_comercial < b.nombre_comercial) return -1;
            if (a.nombre_comercial > b.nombre_comercial) return 1;
            if (a.cif < b.cif) return -1;
            if (a.cif > b.cif) return 1;
            return 0;
          });

          setProveedoresLista(proveedores);
        } else {
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener los proveedores");
      })
  };

  const getDepartamentos = () => {
    const fd = new FormData();
    fd.append("f", "usuarios.getDepartamentos");
    fd.append("token", localStorage.getItem('token'));
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          // console.log("Informacion de Departamentos");
          // console.log(result);
          const departamentos = Object.entries(result.departamentos).map(([id, nombre]) => ({
            id: id,
            nombre: nombre
          }));
          departamentos.unshift({ "id": " ", "nombre": " " })
          setDepartamentosLista(departamentos);
        } else {
          mostrarError(error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener la informacion de los Departamentos ");
      })
  };

  //Funcion para obtener todos los proveedores, departamentos, grupos maquinas
  const obtenerInfoSelect = () => {
    //Obtenemos los proveedores.
    getInfoProveedores();
    //Obtenemos los departamentos
    getDepartamentos();
    //Obtenemos los grupos
    gruposMaquinas();
  };

  const manejarKeyDown = (event) => {
    console.log("Actualmente mover")
    console.log(filaSeleccionada);
    console.log(event);

    if (event.key === 'ArrowUp') {
      // Mover el foco hacia arriba
      event.preventDefault(); // Previene el comportamiento predeterminado del navegador
      if (filaSeleccionada > 0) {
        setFilaSeleccionada(filaSeleccionada - 1);
      }
    } else if (event.key === 'ArrowDown') {
      // Mover el foco hacia arriba
      event.preventDefault(); // Previene el comportamiento predeterminado del navegador
      if (filaSeleccionada > 0) {
        setFilaSeleccionada(filaSeleccionada + 1);
      }
    }
  };

  const clickInfoMaquina = (params, event) => {

    obtenerInfoSelect(); // Obtenermos la información de los select, grupos, proveedor, departamento
    listarTodosPartesHabitacion(params.id);// Obtenemos los partes de las maquinas
    getInfoMaquina(params.id); //Obtenemos toda la información de caracteristicas de la maquina
    getFotosMaquina(params.id); // Obtenemos las imagenes de la maquina



    setMostrarMaquina(true); // Mostramos el Dialog
  };
  const ocultarInfoMaquina = () => {
    setMostrarMaquina(false); //Ocultamos el Dialog
    setListaNuevasImagenMaquina([]); // Galeria de la maquina
    setListaPartesMaquina(''); //Lista de los partes de la maquina

  };
  const comprobarESCInfoMaquina = (event) => {
    if (event.keyCode === 27) {
      ocultarInfoMaquina();
    }
  };
  const limpiarInfoMaquina = () => {
    //Vaciar los campos de infoMaquina
    setMostrarMaquina(false);
    //Vaciamos posible foto seleccionada
    setListaNuevasImagenMaquina([]);
    //Quiza sea necesario
    //setListaPartesMaquina('');

  };

  //Para controlar que acción hacer dependiendo del Tab activo
  const confirmTabsMaquina = () => {
    switch (tabValue) {
      case 0:
        guardarInfoMaquina();
        break;
      case 1:
        setImagenesMaquina();
        break;
      case 2:
        break;
      case 3:
        break;
    }
  };

  const guardarInfoMaquina = () => {
    //Convertimos las array a string y comprobamos si son iguales.
    if (JSON.stringify(copiaInfoMaquina) === JSON.stringify(datosInfoMaquina)) {
      mostrarError("No hay cambios en la maquina");
    } else {
      var str = '';

      Object.keys(datosInfoMaquina).forEach((nombre, i) => {
        //Solo guardaremos los cambios y el id de la maquina
        if (Object.values(datosInfoMaquina)[i] != Object.values(copiaInfoMaquina)[i] || nombre === "id") {
          if (nombre != "nombre_desc") {
            //Comprobamos que el campo no sea la Fecha 
            if (nombre != 'fecha_instalacion') {
              if (Object.values(datosInfoMaquina)[i] != '' && Object.values(datosInfoMaquina)[i] != undefined) {
                if (nombre === 'comentarios') {
                  const observacionesValue = JSON.stringify(Object.values(datosInfoMaquina)[i]);
                  str += nombre + "=" + observacionesValue + "|";
                } else {
                  const paramValue = Object.values(datosInfoMaquina)[i];
                  str += nombre + "=" + paramValue + "|";
                }
              }
            } else {
              let soloFecha = Object.values(datosInfoMaquina)[i].split(" ");
              if (Object.values(datosInfoMaquina)[i] != "" && soloFecha[0] != "0000-00-00") {
                str += nombre + "=" + Object.values(datosInfoMaquina)[i] + " 08:00:00|";
              } else {
                str += nombre + "=NULL|";
              }
            }
          }
        }
      });
      const fd = new FormData();
      fd.append("f", "maquinas.setMaquina");
      fd.append("token", localStorage.getItem('token'));
      fd.append("params", str);
      const requestOptions = {
        method: 'POST',
        body: fd
      };

      fetch(API_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          //console.log(result)
          if (error.id === 0) {
            mostrarError("Maquina: " + datosInfoMaquina.id + " Actualizada", "success");
            setMostrarMaquina(false);
            actualizar();
          } else {
            mostrarError(error.msg);
          }
        })
        .catch(() => {
          mostrarError("Fallo actualizando la maquina " + datosInfoMaquina.id);
        })

      //setMostrarMaquina(false);
    }
  };

  /******************************************************************/
  //                   Fin  Dialog Info Maquina                     //
  /******************************************************************/

  /******************************************************************/
  //                   Inicio  Dialog crear Maquina                 //
  /******************************************************************/

  const mostrarNuevaMaquina = () => {
    SetHotelesLista(JSON.parse(hoteles));
    document.querySelector('.nuevoParte').classList.add('pintado');

    setNuevaMaquina(true);
  };
  const ocultarNuevaMaquina = () => {
    setNuevaMaquina(false);
  };
  const comprobarESCNuevaMaquina = (event) => {
    if (event.keyCode === 27) {
      ocultarNuevaMaquina();
    }
  };
  const limpiarNuevaMaquina = () => {
    document.querySelector('.nuevoParte').classList.remove('pintado');
    setNuevaMaquina(false);
  };

  const crearMaquina = () => {
    console.log('Peticion crear Maquina');
    document.querySelector('.nuevoParte').classList.remove('pintado');
    setNuevaMaquina(false);
  };

  /******************************************************************/
  //                Fin Dialog crear Maquinas                       //
  /******************************************************************/

  /******************************************************************/
  //                    Inicio  Dialog Filtros                      //
  /******************************************************************/

  const estadoMaquina = (event) => {
    if (btnActivo === false) {
      document.querySelector('.abierto').classList.add('pintado');
      setBtnActivo(true);
    } else {
      document.querySelector('.abierto').classList.remove('pintado');
      setBtnActivo(false);
    }
    actualizar();
  };

  //Obtenemos los grupos y departamentos.
  const gruposMaquinas = () => {
    let grupos = [];
    grupos.push({ value: -1, label: '' });
    const fd = new FormData();
    fd.append("f", "maquinas.getGruposMaquinas");
    fd.append("token", localStorage.getItem('token'));
    const requestOptions = {
      method: 'POST',
      body: fd
    };

    fetch(API_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        delete result.error;
        //Recorremos el array de grupos para obtener su id y nombre.
        Object.keys(result.maquinas_grupos).map((id, i) => {
          var nombreMaquina = Object.values(result.maquinas_grupos)[i].nombre;
          var idMaquina = Object.values(result.maquinas_grupos)[i].id;
          grupos.push({ value: idMaquina, label: nombreMaquina });
          return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
        })
        // console.log(grupos)

        setGruposLista(grupos.sort((a, b) => (a.label > b.label) ? 1 : -1));
        //.sort((a, b) => (a.label > b.label) ? 1 : -1)
      })
      .catch(() => {
        mostrarError("No se ha podido listar los Usuarios.");
      })
  }


  const mostrarFiltros = () => {
    gruposMaquinas();
    setActivoFiltro(true);
    SetActualizo(!actualizo);
    setEstadoFiltros(true);
  };
  const comprobarESC = (event) => {
    if (event.keyCode === 27) {
      ocultarFiltros();
    }
  };
  const ocultarFiltros = () => {
    setEstadoFiltros(false);
    actualizar();
  };
  const eliminarFiltros = () => {
    setDatosFiltroMaquina({
      id: '',
      nombre: '',
      n_serie: '',
      grupo: -1, // Puede llegar
      activo: false
    });
    setActivoFiltro(false);
    setEstadoFiltros(false);
    actualizar();
  };

  const actualizarFiltros = (event) => {
    if (event.target.name == "grupo") {
      setDatosFiltroMaquina({
        ...datosFiltroMaquina,
        [event.target.name]: parseInt(event.target.value)
      });
    } else {
      setDatosFiltroMaquina({
        ...datosFiltroMaquina,
        [event.target.name]: event.target.value
      });
    }

  };

  const guardarDatos = () => {
    const datos = datosFiltroMaquina;
    var str = '';
    Object.keys(datos).forEach((nombre, i) => {
      if (Object.values(datos)[i] !== '' && Object.values(datos)[i] !== undefined) {
        str += nombre + "=" + Object.values(datos)[i] + '|'
      }
    });

    setEstadoFiltros(false);
    actualizar();
    setActivoFiltro(true);
  };

  const comprobarEnter = (e) => {
    if (e.key === 'Enter') {
      guardarDatos();
    }
  }

  useEffect(() => {

    let listaHoteles = JSON.parse(hoteles);
    SetHotelesLista(listaHoteles);

    //Si tenemos un hotel guardado en el localStorage utilizando Ajustes.
    let idHotel = localStorage.getItem('idHotelSeleccionado');
    if (estadoInicial) {
      obtenerInfoSelect();
      if (idHotel) {
        SetHotelTrabajo(idHotel);
        setEstadoInicial(false);
      } else {
        SetHotelTrabajo(listaHoteles[0].value);
        setEstadoInicial(false);
      }
    }
    getMaquinas();

  }, [hotelTrabajo, actualizo]);
  return (
    <>
      <div className='container'>
        <div className='content' style={{ "height": "70px" }}>
          <div className='article'>
            <div className='header'>
              <div className='containerAcciones contenidoIzquierda'>

                <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Actualizar"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className="boton"
                      onClick={actualizar}
                    >
                      <RotateLeftIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>

                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Crear Maquina"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className="boton nuevoParte"
                      onClick={mostrarNuevaMaquina}
                    >
                      <AddCircleOutlineIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Filtrar"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className={activoFiltro === true ? "boton filtro pintado" : "boton filtro"}
                      onClick={mostrarFiltros}
                    >
                      <FilterAltIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Maquinas Activas"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className="boton abierto"
                      onClick={estadoMaquina}
                    >
                      <AssignmentLateOutlinedIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className='aside' >
            <div className='header'>
              <TextField
                className='fifty'
                variant="outlined"
                margin="normal"
                label="Hotel"
                name="id_hotel"
                id="id_hotel"
                size="small"
                value={hotelTrabajo ? hotelTrabajo : ""}
                onChange={cambiaHotelTrabajo}
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { maxHeight: { sm: 36 * 8 + 8 } }
                    }
                  }
                }}
              >
                {hotelesLista.map((hotel) => (
                  <MenuItem value={hotel.value} >
                    {hotel.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

        </div>
        <div className='grid-container' onKeyDown={manejarKeyDown}>
          <DataGrid
            className='grid'
            rowHeight={56}//63
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? ' blanco' : ' fondogris blanco'
            }
            rowClassName={(params) =>
              params.rowIndex === filaSeleccionada ? 'fila-seleccionada' : ''
            }
            onRowClick={(params, event) => {
              if (!event.target.closest('button')) {
                clickInfoMaquina(params, event);
              }
            }}
            getRowId={(listaMaquinas) => listaMaquinas.id}
            rows={listaMaquinas}
            columns={columnas}
            maxColumns={8}
            pageSize={12}
            rowsPerPageOptions={[12]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'Nombre', sort: 'asc' }],
              },
            }}
            localeText={{
              noRowsLabel: "No se ha encontrado datos.",
              noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}
            disableColumnMenu
          />
        </div>

        <CargaSpinner contadorSpinner={contadorSpinner} />
        <Snackbar open={openAlert} autoHideDuration={3000} onClose={ocultarAlerta}>
          <Alert onClose={ocultarAlerta} severity={severidad} sx={{ width: '100%' }}>
            {mensaje}
          </Alert>
        </Snackbar>

        <Dialog fullScreen={fullScreen}
          open={nuevaMaquina}
          onClose={comprobarESCNuevaMaquina}
          className='dialogCrearParte'
          aria-labelledby="responsive-dialog-title" id="dialogoNewMaquina">
          <DialogTitle>{'Nueva Maquina'}</DialogTitle>
          <DialogContent className='container-filtros'>
            <form className='nuevoParteForm'>
              <div className='nuevoParteBloque'>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={limpiarNuevaMaquina}>Cancelar</Button>
              <Button className="colorGrupo" onClick={crearMaquina}>Crear</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={mostrarMaquina}
          onClose={comprobarESCInfoMaquina}
          className='dialogCrearParte'
          aria-labelledby="responsive-dialog-title" id="dialogoInfoMaquina">
          <DialogTitle>{'Maquina: ' + datosInfoMaquina.id}</DialogTitle>
          <DialogContent className='container-filtros alturaDialog'>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Información" />
              <Tab label="Imágenes" />
              <Tab label="Documentos" />
              <Tab label="Partes" />
            </Tabs>

            {tabValue === 0 && (
              <form >
                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="ID"
                        name="infoID"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.id ? datosInfoMaquina.id : '') : ''}
                        inputProps={{ className: 'bold', readOnly: true }}
                        InputLabelProps={{ className: 'bold' }}
                      />
                      <TextField
                        label="Hotel"
                        name="infoHotel"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.nombre_desc ? datosInfoMaquina.nombre_desc : '') : ''}
                        inputProps={{ readOnly: true }}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Modelo"
                        name="infoModelo"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.modelo ? datosInfoMaquina.modelo : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, modelo: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="N_Serie"
                        name="infoN_Serie"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.n_serie ? datosInfoMaquina.n_serie : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, n_serie: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Nombre"
                        name="infoNombre"
                        className='observaciones'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.nombre ? datosInfoMaquina.nombre : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, nombre: e.target.value }))}
                        inputProps={{ className: 'bold' }}
                        InputLabelProps={{ className: 'bold', shrink: true }}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Fabricante"
                        name="infoFabricante"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.fabricante ? datosInfoMaquina.fabricante : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, fabricante: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Anyo_Fabricacion"
                        name="infoAnyo_Fabricacion"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        type="number"
                        value={datosInfoMaquina ? (datosInfoMaquina.anyo_fabricacion ? datosInfoMaquina.anyo_fabricacion : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, anyo_fabricacion: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>

                      <TextField
                        label="Grupo"
                        name="infoGrupo"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.id_grupo ? datosInfoMaquina.id_grupo : -1) : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, id_grupo: e.target.value }))}
                        select
                      >
                        {gruposLista.map((grupo) => (
                          <MenuItem key={grupo.value} value={grupo.value}>
                            {grupo.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Autocomplete
                        name="infoMantenedor"
                        className="poperAutoCompletar titulo"
                        size="small"
                        options={proveedoresLista}
                        getOptionLabel={(option) => option.label}
                        value={proveedoresLista.find((option) => option.cif === datosInfoMaquina.cif_mantenedor) || null}
                        onChange={(event, value) => {
                          setDatosInfoMaquina(prevState => ({ ...prevState, cif_mantenedor: value ? value.cif : '' }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Mantenedor" />}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <Autocomplete
                        name="infoInstalador"
                        className="poperAutoCompletar titulo"
                        size="small"
                        options={proveedoresLista}
                        getOptionLabel={(option) => option.label}
                        value={proveedoresLista.find((option) => option.cif === datosInfoMaquina.cif_instalador) || null}
                        onChange={(event, value) => {
                          setDatosInfoMaquina(prevState => ({ ...prevState, cif_instalador: value ? value.cif : '' }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Instalador" />}
                      />

                      <TextField
                        name="infoDepartamento"
                        label="Departamento"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.id_departamento ? datosInfoMaquina.id_departamento : ' ') : ' '}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, id_departamento: e.target.value }))}
                        select
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {departamentosLista.map((departamento) => (
                          <MenuItem key={departamento.id} value={departamento.id}>
                            {departamento.nombre}
                          </MenuItem>
                        ))}
                      </TextField>

                    </div>
                  </div>
                </div>

                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Alto"
                        name="infoAlto"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        type="number"
                        value={datosInfoMaquina ? (datosInfoMaquina.alto ? datosInfoMaquina.alto : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, alto: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Ancho"
                        className='titulo'
                        variant="outlined"
                        name="infoAncho"
                        margin="normal"
                        size="small"
                        type="number"
                        value={datosInfoMaquina ? (datosInfoMaquina.ancho ? datosInfoMaquina.ancho : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, ancho: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Largo"
                        className='titulo'
                        variant="outlined"
                        name="infoLargo"
                        margin="normal"
                        size="small"
                        type="number"
                        value={datosInfoMaquina ? (datosInfoMaquina.largo ? datosInfoMaquina.largo : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, largo: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Fecha_Instalacion"
                        type="date"
                        format={'DD/MM/YYYY'}
                        className='titulo'
                        name="infoF_instalacion"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={datosInfoMaquina ? (datosInfoMaquina.fecha_instalacion ? datosInfoMaquina.fecha_instalacion : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, fecha_instalacion: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='divFiltros'>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Comentarios"
                        className='observaciones '
                        size="small"
                        multiline
                        rows={4}
                        id='infoTextoComentario'
                        name="infoComentario"
                        value={datosInfoMaquina ? (datosInfoMaquina.comentarios ? datosInfoMaquina.comentarios : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, comentarios: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                    </div>
                  </div>
                  <div className='divFiltrosContainer'>
                    <div className='divFiltrosTextField'>
                      <TextField
                        label="Lugar"
                        className='titulo'
                        variant="outlined"
                        margin="normal"
                        size="small"
                        name="infoTitulo"
                        value={datosInfoMaquina ? (datosInfoMaquina.id_lugar ? datosInfoMaquina.id_lugar : '') : ''}
                        onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, id_lugar: e.target.value }))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <div className='divAplazado titulo'>
                        <FormControlLabel
                          control={<Checkbox checked={datosInfoMaquina.activo === '1' || datosInfoMaquina.activo === true ? true : false} color="secondary" name="infoActivo"
                            onChange={(e) => setDatosInfoMaquina(prevState => ({ ...prevState, activo: e.target.checked ? '1' : '0' }))}
                          />}
                          label="Maquina Activa"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {tabValue === 1 && (
              <div>
                <div >
                  <div >
                    <label htmlFor="upload-photo" >
                      <input
                        className='ocultar'
                        //accept="image/*"
                        multiple
                        accept=".jpg, .jpeg, .png"
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={selecImage}
                      />
                      <IconButton component="span" className='boton buscador'>
                        <AddAPhotoIcon className='icon-button ' />
                      </IconButton>
                    </label>
                  </div>
                  {listaNuevasImagenMaquina.length > 0 ?
                    <div className='divImgMaquinasNuevas'>
                      {listaNuevasImagenMaquina.map((foto, j) => {
                        return (
                          <div className='divCentrarImagen' key={foto.id}>
                            <IconButton className='iconoBorrarImg' onClick={() => borrarImagen(foto.id)}>
                              <CancelIcon className=' ' />
                            </IconButton>
                            <img className="img-preview-Editar" src={foto.ruta} onClick={() => ventanaImagen(foto.ruta)} />
                          </div>
                        );
                      })
                      }
                    </div>
                    : ''
                  }
                </div>
                <hr />
                <div className='divImgMaquinasGuardadas'>
                  {/*  Imagenes Guardadas de la maquina */}
                  {listaImagenesMaquina.length > 0 ?
                    listaImagenesMaquina.map((foto, j) => {
                      return <div className='divCentrarImagen'>
                        <IconButton className='iconoBorrarImg' onClick={() => confirmDelImgMaquina(foto.id_foto)}>
                          <CancelIcon className=' ' />
                        </IconButton>
                        <img key={foto.id} className="img-preview-Historico" src={Api_Maquinas_Img + datosInfoMaquina.id + "/min_" + foto.archivo}
                          style={{ "width": "auto", "max-width": "90vh" }}
                          onError={({ currentTarget }) => {
                            currentTarget.src = Api_Maquinas_Antiguo_Img + datosInfoMaquina.id + "/min_" + foto.archivo;
                            currentTarget.onerror = null; // prevents looping
                          }}
                          onClick={(event) => ventanaImagen(event.target.src)}
                        />
                      </div>
                    })
                    :
                    <div>
                      <p>No hay imagenes Guardadas</p>
                    </div>
                  }

                </div>
              </div>
            )}

            {tabValue === 2 && (
              <div className="centrar"><p>Contenido de la pestaña Documentos</p></div>
            )}
            {tabValue === 3 && (
              <div>
                <div className='infoPartesHab'>
                  <List>
                    {listaPartes.length === 0 ? (
                      <div className="centrar"><p>No hay partes</p></div>
                    ) : listaPartes
                    }
                  </List>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={limpiarInfoMaquina}>Cancelar</Button>
              <Button className="colorGrupo" onClick={confirmTabsMaquina}>{tabValue === 1 || tabValue === 2 ? "Subir" : "Guardar"}</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        {listaFotosParte.length > 0 ?
          <Dialog fullScreen={fullScreen}
            open={galeriaImagenes}
            onClose={ocultarGaleria}
            aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
          >
            <DialogTitle>Galeria fotos Parte {idParte}</DialogTitle>
            <DialogContent className='container-filtros '>
              {
                listaFotosParte ?
                  listaFotosParte[posImage].archivo !== undefined || listaFotosParte[posImage].archivo !== "" ?
                    <div>
                      <IconButton component="span" className='iconoFoto buscador' onClick={abrirVentanaImagen} >
                        <AddAPhotoIcon className='icon-button ' />
                      </IconButton>

                      <img id="imagenGaleria" className="mdc-image-list__image"
                        alt={"Imagen del Parte " + idParte + " Nombre archivo: " + listaFotosParte[posImage].archivo}
                        src={"https://api.grupotel.com/workers.2/imagenes/parte/" + idParte + "/" + listaFotosParte[posImage].archivo}
                        style={{ "width": "auto" }} onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "https://api.grupotel.com/workers/imagenes/partes/" + idParte + "/" + listaFotosParte[posImage].archivo;
                        }} />

                    </div>
                    : ""
                  : ""
              }

            </DialogContent>
            <DialogActions className='centrar'>
              {
                listaFotosParte.length > 1 ?
                  <div className='direccion ' id='direccionImagen'>
                    <ButtonGroup className=" colorGrupo" variant="contained" aria-label="outlined button group">
                      <IconButton component="span" className='direccionFlecha' onClick={prevImagen}>
                        <WestOutlinedIcon />
                      </IconButton>
                      <Button className="colorGrupo" onClick={ocultarGaleria}>Cerrar</Button>
                      <IconButton component="span" className=' direccionFlecha' onClick={nextImagen}>
                        <EastOutlinedIcon />
                      </IconButton>
                    </ButtonGroup>
                    <p>{posImage + 1} / {listaFotosParte.length}</p>
                  </div>
                  :
                  <ButtonGroup variant="contained" aria-label="outlined button group">
                    <Button className="colorGrupo" onClick={ocultarGaleria}>Cerrar</Button>
                  </ButtonGroup>
              }
            </DialogActions>
          </Dialog>
          : ""}
        {listaPdfParte.length > 0 ?
          <Dialog fullScreen={fullScreen}
            open={galeriaPdf}
            onClose={ocultarPdf}
            aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
          >
            <DialogTitle>Listado Pdf Parte: {idParte}</DialogTitle>
            <DialogContent className='container-filtros '>
              {
                listaPdfParte ?
                  listaPdfParte.map((pdf, i) => {
                    return <ListItemButton onClick={() => mostrarPdf(listaPdfParte[i].archivo)} >{listaPdfParte[i] ? listaPdfParte[i].archivo : ''}</ListItemButton>
                  })
                  : ""
              }
            </DialogContent>
            <DialogActions className='centrar'>
              <ButtonGroup variant="contained" aria-label="outlined button group">
                <Button className="colorGrupo" onClick={ocultarPdf}>Cerrar</Button>
              </ButtonGroup>
            </DialogActions>
          </Dialog>
          : ""
        }


        <Dialog fullScreen={fullScreen}
          open={estadoBorrarImagen}
          //disableEscapeKeyDown
          onClose={cancelDelImgMaquina}
          aria-labelledby="responsive-dialog-title" id="dialogoConfirmarDel">
          <DialogTitle>Borrar Imagen</DialogTitle>
          <DialogContent>
            <div className='bloque-inputs-filtros '>
              Seguro que desea borrar la imagen?
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={() => cancelDelImgMaquina()}>Cancelar</Button>
              <Button className="colorGrupo" onClick={() => delImgMaquina()}>Confirmar</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen={fullScreen}
          open={estadoFiltros}
          //disableEscapeKeyDown
          onClose={comprobarESC}
          aria-labelledby="responsive-dialog-title" id="dialogoFiltros">
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>
            <form onSubmit={(e) => { e.preventDefault(); guardarDatos(); }}>
              <div className='bloque-inputs-filtros '>
                <TextField
                  label="ID"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  onKeyPress={(ev) => { comprobarEnter(ev) }}
                  name="id"
                  size="small"
                  value={datosFiltroMaquina.id ? datosFiltroMaquina.id : ''}
                  onChange={actualizarFiltros}
                />
                <TextField
                  label="Nombre"
                  variant="outlined"
                  margin="normal"
                  name="nombre"
                  onKeyPress={(ev) => { comprobarEnter(ev) }}
                  size="small"
                  value={datosFiltroMaquina.nombre ? datosFiltroMaquina.nombre : ''}
                  onChange={actualizarFiltros}
                />
                <TextField
                  label="N_Serie"
                  variant="outlined"
                  margin="normal"
                  name="n_serie"
                  onKeyPress={(ev) => { comprobarEnter(ev) }}
                  size="small"
                  value={datosFiltroMaquina.n_serie ? datosFiltroMaquina.n_serie : ''}
                  onChange={actualizarFiltros}
                />
                <TextField
                  label="Grupo"
                  variant="outlined"
                  margin="normal"
                  name="grupo"
                  size="small"
                  value={datosFiltroMaquina.grupo !== -1 ? datosFiltroMaquina.grupo : ''}
                  onChange={actualizarFiltros}
                  select
                >
                  {gruposLista.map((grupo) => (
                    <MenuItem key={grupo.value} value={grupo.value}>
                      {grupo.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={eliminarFiltros}>Cancelar</Button>
              <Button className="colorGrupo" onClick={guardarDatos}>Filtrar</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
        <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />

      </div>
    </>
  );
}
export default Maquinas;

import React, { useEffect, useState } from 'react'
import { Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, useMediaQuery, ButtonGroup, MenuItem, TextField, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MostrarError from '../../components/AccionesPartes/MostrarError';

import './Ajustes.css';

function Ajustes() {
    const API_URL = 'https://api.grupotel.com/workers.2/';

    let hoteles = localStorage.getItem('hoteles');
    let nombreUsuario = localStorage.getItem('nombre');
    let userName = localStorage.getItem('nombreUsuario');
    let departamento = localStorage.getItem('nombreDepartamento');
    let idHotel = localStorage.getItem('idHotelSeleccionado');

    const [hotelesLista, SetHotelesLista] = useState([]);
    const [hotelTrabajo, SetHotelTrabajo] = useState(0);

    //const [actualizo, SetActualizo] = useState(true);

    //Boolean para mostrar o ocultar el dialog de modificar la contraseña
    const [dialogModificarPassword, setDialogModificarPassword] = React.useState(false);
    //Boolean para modificar los * por Texto.
    const [mostrarPassword, setMostrarPassword] = React.useState(false);
    //Boolean para controlar y mostrar el error al Usuario 
    const [controlErrorPassword, setControlErrorPassword] = useState(false);
    //Funcion para modificar el boolean de visibilidad de la contraseña.
    const clickMostrarPassword = () => setMostrarPassword((show) => !show);

    //Boolean para mostrar o ocultar el dialog de modificar el Nombre
    const [dialogModificarNombre, setDialogModificarNombre] = React.useState(false);
    //Variables para Mostrar la contraseña y no ver los **. 
    const [mostrarPasswordNombre, setMostrarPasswordNombre] = React.useState(false);
    //Boolean para controlar y mostrar el error al Usuario 
    const [controlErrorNombre, setControlErrorNombre] = useState(false);
    const clickMostrarPasswordNombre = () => setMostrarPasswordNombre((show) => !show);
    const [modificarNombre, setModificarNombre] = useState('');



    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    //Conjunto para mostrar un Alert con el resultado de la petición
    const [mensajeError, setMensajeError] = useState('');
    const [tipoError, setTipoError] = useState('');
    const [contadorError, setContadorError] = useState(1);
    const [estadoAlert, setEstadoAlert] = React.useState(false);

    const mostrarError = (mensajeError, tipoError) => {
        setMensajeError(mensajeError);
        if (tipoError) {
            setTipoError(tipoError);
        } else {
            setTipoError("error");
        }
        setContadorError(contadorError + 1);
        setEstadoAlert(true);
        const timer2 = setTimeout(() => {
      setEstadoAlert(false);
    }, 3000);
        return () => clearTimeout(timer2);
    };

    const cambiaHotelTrabajo = (event) => {
        if (event.target.name === 'id_hotel') {
            SetHotelTrabajo(event.target.value);
        }
    }
    const guardarHotelTrabajo = () => {
        localStorage.setItem('idHotelSeleccionado', hotelTrabajo);
    }
    const obtenerListaHoteles = () => {
        let listaHoteles = JSON.parse(hoteles);
        if (idHotel) {
            SetHotelTrabajo(idHotel);
        }
        //listaHoteles.unshift({ "value": "", "label": "" });
        SetHotelesLista(listaHoteles);
    }
    const obtenerNombreDepartamento = () => {
        const fd = new FormData();
        fd.append("f", "user.getNombreDepartamento");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_user", localStorage.getItem('id'));
        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                departamento = result.nombre_departamento;
                localStorage.setItem('nombreDepartamento', result.nombre_departamento);
            })
            .catch(() => {
                mostrarError("Problemas Api obteniendo el nombre del departamento.");
            })

    }
    const changePassword = () => {
        setDialogModificarPassword(true);
    }
    const ocultarChangePassword = () => {
        setControlErrorPassword(false);
        setDialogModificarPassword(false);
    }
    const comprobarPassword = () => {
        //Funcion para comprobar que la contraseña sea valida. Si lo es comprobamos que la contraseña actual 
        var password = document.getElementById('actualPassword').value;
        //Comprobaciones de la contraseña.
        //Requisitos: Minimo 8 caracteres , 1 mayuscula, 1 numero.
        var newPassword = document.getElementById('nuevaPassword').value;
        var md5 = require('md5');
        var newPasswordConfirm = document.getElementById('nuevaPassword2').value;
        var estado = false;
        //Comprobamos que la nueva contraseña y la actual no sean iguales y no esten vacias.
        if (newPassword.trim() !== "" && password.trim() !== "" && password !== newPassword) {

            //Comprobamos que las nuevas contraseñas no esten vacias.
            if (newPassword.trim() !== "" && newPasswordConfirm.trim() !== "") {
                //Comprobamos que sean iguales
                if (newPassword === newPasswordConfirm) {
                    const regex = /^(?=(?:.*\d){1})(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})\S{8,}$/gm;
                    let m;

                    const updateEstado = () => {
                        estado = true;
                    };

                    while ((m = regex.exec(newPassword)) !== null) {
                        if (m.index === regex.lastIndex) {
                            regex.lastIndex++;
                        }

                        m.forEach(updateEstado);
                    }
                    if (estado) {
                        setControlErrorPassword(false);
                        const fd = new FormData();
                        fd.append("f", "user.changePassword");
                        fd.append("token", localStorage.getItem('token'));
                        fd.append("password", md5(password));
                        fd.append("newPassword", md5(newPassword));

                        const requestOptions = {
                            method: 'POST',
                            body: fd
                        };

                        fetch(API_URL, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.error.id === 0) {
                                    mostrarError("Contraseña cambiada", "success");
                                    ocultarChangePassword();
                                } else {
                                    mostrarError(result.error.msg);
                                }
                            })
                            .catch(() => {
                                mostrarError("Problemas Api actualizando la contraseña.");
                            })

                    } else {
                        mostrarError("Revise la nueva Contraseña");
                        setControlErrorPassword(true);
                    }
                } else {
                    mostrarError("Las contraseñas introducidas son Distintas.");
                    setControlErrorPassword(true);
                }
            } else if (newPassword.trim() === "" && newPasswordConfirm.trim() === "") {
                mostrarError("Las contraseñas estan vacias.");
                setControlErrorPassword(true);
            } else if (newPassword.trim() === "") {
                mostrarError("Introduzca la Contraseña nueva");
                setControlErrorPassword(true);
            } else {
                mostrarError("Introduzca confirmar Contraseña.");
                setControlErrorPassword(true);
            }
        } else {
            mostrarError("La nueva Contraseña tiene que ser diferente a la actual.");
            setControlErrorPassword(true);
        }
    }
    const changeUser = () => {
        setDialogModificarNombre(true);
    }
    const ocultarChangeUser = () => {
        setDialogModificarNombre(false);
        setModificarNombre('');
    }
    const cambiarNombre = (event) => {
        setModificarNombre(event.target.value);
    }

    const comprobarUser = () => {
        var password = document.getElementById('actualPassword').value;
        var md5 = require('md5');

        if (modificarNombre.trim() !== '' && password.trim() !== '') {
            if (modificarNombre !== nombreUsuario) {

                const fd = new FormData();
                fd.append("f", "user.changeName");
                fd.append("token", localStorage.getItem('token'));
                fd.append("password", md5(password));
                fd.append("newUserName", modificarNombre);

                const requestOptions = {
                    method: 'POST',
                    body: fd
                };

                fetch(API_URL, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.error.id === 0) {
                            mostrarError("Se ha modificado el nombre", "success");
                            localStorage.setItem('nombre', modificarNombre);
                            ocultarChangeUser();
                        } else {
                            mostrarError(result.error.msg);
                        }
                    })
                    .catch(() => {
                        mostrarError("Problemas Api actualizando el nombre de Usuario.");
                    })
            } else {
                mostrarError("No se puede introducir el mismo nombre de Usuario. Actual: " + nombreUsuario);
            }
        } else if (modificarNombre.trim() === '' && password.trim() === '') {
            mostrarError("Introduzca la Contraseña y el nuevo nombre para el Usuario. ");

        } else if (modificarNombre.trim() === '') {
            mostrarError("No puede introducir el nombre vacio.");
        } else {
            mostrarError("Introduzca su contraseña. Para verificar su identidad.");
        }

    }
    useEffect(() => {//Obtenemos los hoteles y seleccionamos el hotel preferido del usuario.
        obtenerListaHoteles();
    },[])

    useEffect(() => {
        
        //Si no tenemos guardado el nombre del Departamento del Usuario.
        if (departamento === null) {
            obtenerNombreDepartamento();
        }
    }, [departamento, obtenerNombreDepartamento])

    return (
        <div className='container'>
            <div className='content'>
                <div className='article'>
                    <div className='header'>
                        <div className='containerAcciones contenidoIzquierda'>
                            <div>
                                <h3>Ajustes de Usuario:</h3>
                            </div >
                        </div>
                    </div>
                    <div className='grid-container contenedorAjustes'>
                        <div className='datos-container'>
                            <div className='divTextBoton'>
                                <TextField
                                    className='divInfoUsuario'
                                    variant="outlined"
                                    margin="normal"
                                    label="Hotel"
                                    name="id_hotel"
                                    value={hotelTrabajo ? hotelTrabajo : ""}
                                    size="small"
                                    onChange={cambiaHotelTrabajo}
                                    select
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                            }
                                        }
                                    }}
                                >
                                    {hotelesLista.map((hotel) => (
                                        <MenuItem value={hotel.value} >
                                            {hotel.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                            <div className='informacionUsuario'>
                                <div className='seleccionHotel'>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        label="Usuario"
                                        name="user"
                                        className='divInfoUsuario'
                                        size="small"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        value={userName ? userName : ''}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        label="Departamento"
                                        name="departamento"
                                        className='divInfoUsuario'
                                        size="small"
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                        value={departamento ? departamento : ''}
                                    />

                                </div>
                                <div className='divTextBoton'>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        label="Nombre"
                                        name="nombre"
                                        className='divInfoUsuario'
                                        size="small"
                                        inputProps=
                                        {{
                                            readOnly: true,
                                            sx: {
                                                cursor: "pointer!important"
                                            }
                                        }}
                                        value={nombreUsuario ? nombreUsuario : ''}
                                        onClick={changeUser}
                                    />
                                    <div className='divInfoUsuario'>
                                        <Button className="passwordBoton" onClick={changePassword}>Cambiar Contraseña</Button>
                                    </div>
                                </div>
                                <div className='divTextBoton centrar margenBotonGuardar'>
                                    <div >
                                        <Button className="colorGrupo centrar" onClick={guardarHotelTrabajo}>Guardar</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='aside' >
                    <div className='header'>
                        <div className='containerAcciones contenidoIzquierda'>
                            <div>
                                <h3>Configuracion Usuario:</h3>
                            </div >
                        </div>
                    </div>
                </div>
            </div>
            <Dialog fullScreen={fullScreen}
                open={dialogModificarPassword}
                onClose={ocultarChangePassword}
                aria-labelledby="responsive-dialog-title" id='dialogPassword'>
                <DialogTitle className='centrar'>Cambio de Contraseña</DialogTitle>
                <DialogContent className='container-filtros '>
                    <div className='divPassword'>
                        <FormControl
                            name="actualPassword"
                            label="Contraseña actual"
                            margin="normal"
                            required

                            error={controlErrorPassword}
                            autoComplete="current-password"
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    comprobarPassword();
                                }
                            }}
                        >
                            <InputLabel >
                                Contraseña actual
                            </InputLabel>
                            <OutlinedInput
                                id="actualPassword"
                                type={mostrarPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={clickMostrarPassword}
                                        >
                                            {mostrarPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Contraseña actual"
                            />
                        </FormControl>
                        <Divider className='estiloDivider'></Divider>
                        <Typography variant="body2" gutterBottom className='justificarTexto' >
                            <strong>Requisitos contraseña: </strong>
                            8 caracteres como mínimo, 1 letra mayúscula, 1 minúscula y 1 numero.
                        </Typography>
                        <Divider className='estiloDivider'></Divider>
                        <FormControl
                            name="nuevaPassword"
                            label="Nueva Contraseña"
                            margin="normal"
                            required

                            error={controlErrorPassword}
                            autoComplete="current-password"
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    comprobarPassword();
                                }
                            }}
                        >
                            <InputLabel >
                                Nueva Contraseña
                            </InputLabel>
                            <OutlinedInput
                                id="nuevaPassword"
                                type={mostrarPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={clickMostrarPassword}
                                        >
                                            {mostrarPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Nueva Contraseña"
                            />
                        </FormControl>
                        <FormControl
                            name="nuevaPassword2"
                            label="Confirmar Contraseña"
                            margin="normal"
                            required

                            error={controlErrorPassword}
                            autoComplete="current-password"
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    comprobarPassword();
                                }
                            }}
                        >
                            <InputLabel>
                                Confirmar Contraseña
                            </InputLabel>
                            <OutlinedInput
                                id="nuevaPassword2"
                                type={mostrarPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={clickMostrarPassword}
                                        >
                                            {mostrarPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirmar Contraseña"
                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions className='centrar'>
                    <ButtonGroup variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarChangePassword}>Cerrar</Button>

                        <Button className="colorGrupo" onClick={comprobarPassword}>Confirmar</Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
            <Dialog fullScreen={fullScreen}
                open={dialogModificarNombre}
                onClose={ocultarChangeUser}
                aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
            >
                <DialogTitle className='centrar'>Cambiar Nombre de Usuario: </DialogTitle>
                <DialogContent className='container-filtros '>
                    <div className='divPassword'>
                        <Typography variant="body2" gutterBottom className='justificarTexto centrar' >
                            <strong>{nombreUsuario} </strong>

                        </Typography>
                        <FormControl
                            name="actualPassword"
                            label="Contraseña"
                            margin="normal"
                            required
                            error={controlErrorNombre}
                            size="small"
                            autoComplete="current-password"
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    comprobarUser();
                                }
                            }}
                        >
                            <InputLabel >
                                Contraseña
                            </InputLabel>
                            <OutlinedInput
                                id="actualPassword"
                                type={mostrarPasswordNombre ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={clickMostrarPasswordNombre}
                                        >
                                            {mostrarPasswordNombre ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Contraseña"
                            />
                        </FormControl>
                        <Typography variant="body2" gutterBottom className='justificarTexto' >
                            <strong>Introduzca su contraseña: </strong>
                            Para verificar su identidad.
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            label="Nuevo Nombre"
                            name='nuevoNombre'
                            size="small"
                            value={modificarNombre ? modificarNombre : ''}
                            onChange={cambiarNombre}
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    comprobarUser();
                                }
                            }}
                        />

                    </div>
                </DialogContent>
                <DialogActions className='centrar'>
                    <ButtonGroup variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarChangeUser}>Cerrar</Button>
                        <Button className="colorGrupo" onClick={comprobarUser}>Confirmar</Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
            <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
        </div>
    )
}
export default Ajustes;


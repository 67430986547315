import React from 'react';
import { Button, ListItemText } from '@mui/material';

function Prueba() {
    // Los meses para saber qu� escribir.
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    // Funci�n para obtener los d�as del mes. Esto es para saber cu�ntas iteraciones hace el bucle.
    const daysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    // Funci�n para renderizar cada cuadro del d�a
    const renderDay = (monthIndex, day, year) => {
        const date = new Date(year, monthIndex, day);
        const isWeekend = date.getDay() === 0 || date.getDay() === 6; // 0 es Domingo, 6 es S�bado
        const buttonClass = isWeekend ? 'vacaciones-button finde' : 'vacaciones-button';
        return (
            <div className='vacaciones-dia' key={`day-${monthIndex}-${day}`}>
                
                <Button className={buttonClass}>
                    <div className='vacaciones-dia-flex-absolute'>
                    {monthIndex === 3 
                    ? day === 15 ? <span className='vacaciones-texto-absoluto'>-</span> 
                    : day === 16 ? <span className='vacaciones-texto-absoluto'>-</span> 
                    : day === 17 ? <span className='vacaciones-texto-absoluto'>-</span> 
                    : day === 18 ? <><span className='vacaciones-texto-absoluto'>-</span><span className='vacaciones-texto-absoluto colorSalida'>&nbsp;</span> </>
                    : day === 19 ? <><span className='vacaciones-texto-absoluto'>-</span><span className='vacaciones-texto-absoluto colorSalida'>&nbsp;</span><span className='vacaciones-texto-absoluto colorSalidaHoy'>&nbsp;</span> </>
                    : day === 22 ? <><span className='vacaciones-texto-absoluto'>-</span><span className='vacaciones-texto-absoluto colorSalida'>&nbsp;</span><span className='vacaciones-texto-absoluto colorSalidaHoy'>&nbsp;</span><span className='vacaciones-texto-absoluto colorSalidaHoy'>&nbsp;</span></>
                    : day === 23 ? <><span className='vacaciones-texto-absoluto'>-</span><span className='vacaciones-texto-absoluto colorSalida'>&nbsp;</span> </>
                    : day === 24 ? <><span className='vacaciones-texto-absoluto'>-</span><span className='vacaciones-texto-absoluto colorSalida'>&nbsp;</span> </>
                    : day === 25 ? <span className='vacaciones-texto-absoluto'>-</span> 
                    : day === 26 ? <span className='vacaciones-texto-absoluto'>-</span> 
                    : '' 
                    : ''}
                    </div>
                    <span className='vacaciones-texto'>{day}</span>
                </Button>
            </div>
        );
    };

    // Funci�n para renderizar el contenedor del mes
    const renderMonth = (monthIndex, year) => {
        return (
            <div className='vacaciones-mes-bloque' key={`month-${monthIndex}`}>
                <ListItemText className='vacaciones-mes-bloque-texto' key={months[monthIndex]} value={months[monthIndex]}>
                    {months[monthIndex]}
                </ListItemText>
                {[...Array(daysInMonth(monthIndex, year)).keys()].map(day => renderDay(monthIndex, day + 1, year))}
            </div>
        );
    };

    const currentYear = new Date().getFullYear();

    return (
        <div className="full-width">
            {[...Array(12).keys()].map(monthIndex => renderMonth(monthIndex, currentYear))}
        </div>
    );
}

export default Prueba;

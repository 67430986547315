import React, { useEffect, useCallback } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function CargaSpinner({ contadorSpinner }) {
    const [open, setOpen] = React.useState(false);

    //Funcion para mostrar el Backdrop con el Spinner Circular.
    // const mostrarError = () => {
    //     setOpen(contadorSpinner);//Cambiamos el estado del Backdrop.
    // };

    //useCallback para asegurarnos de que la función mostrarError se memorice y se vuelva a crear solo cuando contadorSpinner cambie
    const mostrarError = useCallback(() => {//
        setOpen(contadorSpinner); // Cambiamos el estado del Backdrop.
    }, [contadorSpinner]);

    //Si se modifica el contador se carga el componente MostrarError. Este lanza un Alert con el mensaje que le pasemos.
    useEffect(() => {
        mostrarError();
    }, [contadorSpinner])
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default CargaSpinner;
import React, { useEffect, useState } from 'react';
import {
  Button, Divider, TextField, Grid, Typography, IconButton, InputAdornment, FormControl,
  InputLabel, OutlinedInput
} from '@mui/material/';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import MostrarError from '../../components/AccionesPartes/MostrarError';
import './Login.css';

export default function Login() {

  const [mostrarPassword, setMostrarPassword] = React.useState(false);
  const [uIntranet, setUIntranet] = useState('')
  const [pwIntranet, setPwIntranet] = useState('')

  //Conjunto para mostrar un Alert con el resultado de la petición
  const [mensajeError, setMensajeError] = useState('');
  const [tipoError, setTipoError] = useState('');
  const [contadorError, setContadorError] = useState(1);
  const [estadoAlert, setEstadoAlert] = React.useState(false);
  //Funcion basica para mostrar alert de errores o success.
  const mostrarError = (mensajeError, tipoError) => {
    setMensajeError(mensajeError);
    if (tipoError) {
      setTipoError(tipoError);
    } else {
      setTipoError("error");
    }
    setContadorError(contadorError + 1);
    setEstadoAlert(true);
    const timer2 = setTimeout(() => {
      setEstadoAlert(false);
    }, 3000);
    return () => clearTimeout(timer2);
  };

  let token = localStorage.getItem('token');

  //Boolean para modificar el textfield del Usuario.
  const [controlErrorUser, setControlErrorUser] = useState(false);
  //Boolean para modificar el textfield del Usuario.
  const [controlErrorPassword, setControlErrorPassword] = useState(false);

  useEffect(() => {
    //En el caso de que nos llegue caducado  y no el token.
    if (token === 'CADUCADO') {
      mostrarError('Token caducado, vuelva a acceder.');
    }
    
    // comprobarLogin();
  }, [token, mostrarError])
  useEffect(() => {
    console.log(window.location.href);
    var url = new URL(window.location.href);
  
    // Obtener los parámetros de la URL
    var params = new URLSearchParams(url.search);
  
    // Obtener el valor de la variable 'u'
    setUIntranet(params.get('u'));
    
    // Obtener el valor de la variable 'pw'
    setPwIntranet(params.get('pw'));
  
  }, []);

  //Funcion para modificar el boolean de visibilidad de la contraseña.
  const clickMostrarPassword = () => setMostrarPassword((show) => !show);

  // Funcion que gestiona el login y guarda los datos en el local storage.
  const handleLogin = (Usuario, Password) => {
    try {
      const fd = new FormData();
      fd.append("f", "login");
      fd.append("u", Usuario);
      fd.append("pw", Password);

      const requestOptions = {
        method: 'POST',
        body: fd
      };
      fetch('https://api.grupotel.com/workers.2/', requestOptions)
        .then(response => response.json())
        .then(result => {
          let error = result.error;
          if (error.id === 0) {
            //Obtenemos  guardamos el Token del Usuario.
            let token = result.token;
            localStorage.setItem('token', token);
            //Guardamos las secciones a las que tiene permiso el Usuario.
            let secciones = result.secciones;
            let seccionesArr = Object.values(secciones)
            localStorage.setItem('secciones', seccionesArr);
            //Guardamos toda la informacion que nos llegue del Usuario.
            let usuario = result.usuario;
            Object.keys(usuario).forEach((key, i) => {
              localStorage.setItem(key, Object.values(usuario)[i]);
            });
            localStorage.setItem('nombreUsuario', Usuario);
            let hotelesLista = result.hoteles;
            let hoteles = [];

            Object.keys(hotelesLista).forEach((id_hotel, i) => {
              var hotel = Object.values(hotelesLista)[i];
              var hotelObj = {};
              Object.keys(hotel).forEach((infoHotel, j) => {
                if (infoHotel === "nombre") {
                  //hotelObj[id_hotel] = Object.values(hotel)[j];
                  hotelObj = { value: id_hotel, label: Object.values(hotel)[j] };
                  //{ value: '5', label: 'Esperando Respuesta', },
                }
              });
              hoteles.push(hotelObj);
            });

            //Ordenamos alfabeticamente los hoteles.
            hoteles = hoteles.sort(function (a, b) {
              var textA = a.label.toUpperCase();
              var textB = b.label.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            //En el caso de no detectar un hotel guardado ponemos el primer hotel disponible.
            let idHotel = localStorage.getItem('idHotelSeleccionado');
            if (!idHotel) {
              if (hoteles.length >= 1) {
                localStorage.setItem('idHotelSeleccionado', hoteles[0].value);
              }
            } else {//Comprobar que el hotel seleccionado este en el array de hoteles sino cambiar por el primer valor.
              const estaPresente = hoteles.some((hotel) => hotel.value === idHotel);
              if (!estaPresente) {
                localStorage.setItem('idHotelSeleccionado', hoteles[0].value);
              }
            }
            hoteles = JSON.stringify(hoteles);
            localStorage.setItem('hoteles', hoteles);
            window.location.reload();

          } else {
            //Casos: login incorrecto, usuario sin hoteles o usuario sin secciones.
            mostrarError(error.msg);
          }
        });
    } catch (error) {
      mostrarError(error);
    }
  }

  //Funcion para comprobar la informacion introducida en el Login.
  const comprobarLogin = () => {

    var Usuario = document.getElementById('username').value;
    var md5 = require('md5');
    var pass = document.getElementById('password').value;
    var Password = md5(pass);
    setControlErrorUser(false);
    setControlErrorPassword(false);
    //Si nos llega el Usuario y la Contraseña
    if (Usuario !== "" && Usuario !== undefined && pass !== "" && pass !== undefined) {
      handleLogin(Usuario, Password);
      //En caso de no tener vacio el Usuario y Contraseña.
    } else if (Usuario === "" && pass === "") {
      setControlErrorUser(true);//Activamos el error del TextField
      setControlErrorPassword(true);
      mostrarError("Debe introducir el Usuario y la Contraseña");
    } else if (Usuario === "") {
      setControlErrorUser(true);
      mostrarError("Falta el Usuario");
    } else {
      setControlErrorPassword(true);
      mostrarError("Falta la Contraseña");
    }

  }
  return (
    <div >
      <Grid className='fullHeight' container component="main">
        <Grid
          className='test'
          item
          xs={12}
          sm={8}
          md={5}
          elevation={1}
        >
          <div className="login-form-container">
            <img id="login-logo" src="/grupotel2-sinFondo.png" alt="logo" />
            <Divider orientation="vertical" flexItem className='vertical-divider' />
            <div>
              <Typography component="h1" variant="h5">
                Acceder
              </Typography>
              <form noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Usuario"
                  name="username"
                  size="small"
                  error={controlErrorUser}
                  value={uIntranet}
                  onChange={(e) => setUIntranet(e.target.value)} // Corrección aquí
                  autoFocus
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      handleLogin();
                    }
                  }}
                />
                <FormControl
                  name="password"
                  label="Contraseña"
                  margin="normal"
                  required
                  fullWidth
                  error={controlErrorPassword}
                  size="small"
                  autoComplete="current-password"
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      comprobarLogin();
                    }
                  }}
                >
                  <InputLabel >
                    Contraseña
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    onChange={(e) => setPwIntranet(e.target.value)} // Corrección aquí
                    value={pwIntranet}
                    type={mostrarPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={clickMostrarPassword}
                        >
                          {mostrarPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Contraseña"
                  />
                </FormControl>
                <Button
                className="grupotel-blue"
                  type="button"
                  fullWidth
                  variant="contained"
                  onClick={comprobarLogin}
                  sx={{ height: 54 }}
                >
                  Acceder
                </Button>
              </form>
            </div>

          </div>
        </Grid>
      </Grid>

      <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />

    </div>
  );
}
import React, { useEffect, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

function MostrarError({ mensajeEr, tipoError , contadorError, estadoAlert}) {
    //Conjunto para mostrar un Alert con el resultado de la petición
    const [openAlert, setOpenAlert] = React.useState(false);
    const [mensaje, setMensaje] = useState('');
    const [severidad, setSeveridad] = useState('error');
    const [count, setCount] = useState(0);
    const [numeroError, setNumeroError] = useState(0);

    //Funcion para mostrar el Alert.
    const mostrarError = () => {
        setMensaje(mensajeEr);//El mensaje ha mostrar por pantalla
        setSeveridad(tipoError);//El tipo de Alert: success, error
        setOpenAlert(true);//Cambiamos el estado del Alert.
        setCount(count + 1);//Actualizamos el contador 
        setNumeroError(contadorError);
    };
    //Funcion para ocultar el Alert.
    const ocultarAlerta = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };
    //Si se modifica el contador se carga el componente MostrarError. Este lanza un Alert con el mensaje que le pasemos.
    useEffect(() => {
        if (estadoAlert !== undefined) {//Si le llega el estadoAlert
            setOpenAlert(estadoAlert);
        }
        //Comprobamos que el mensaje sea diferente al ultimo mostrado.
        if (contadorError !== numeroError ) {
            if(mensajeEr){
                mostrarError();
            }
        }
    }, [estadoAlert, contadorError, mensajeEr, numeroError, mostrarError])

    return (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={ocultarAlerta} sx={{'z-index': '1400!important',position: 'fixed!important'}} >
            <Alert onClose={ocultarAlerta} severity={severidad} sx={{ width: '100%'}}>
                {mensaje}
            </Alert>
        </Snackbar>
    )
}
export default MostrarError;
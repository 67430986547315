import React, { useEffect, useState } from 'react'
import { Tooltip, styled, InputAdornment, tooltipClasses, Zoom, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, useMediaQuery, ButtonGroup, Divider, TextField, MenuItem, List, ListItemButton, ListItem, ListItemText, IconButton, Box, Collapse, Tab, Tabs, Checkbox, OutlinedInput, InputLabel, Chip, FormControl, Select, Autocomplete } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import PrintIcon from '@mui/icons-material/Print';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AlarmAddOutlinedIcon from '@mui/icons-material/AlarmAddOutlined';
import SearchIcon from '@mui/icons-material/Search';

import MostrarError from '../AccionesPartes/MostrarError';
import './AccionesPisos.css';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));
function AccionesPisos({ idHab, idHotel, fechaId, limpiaHab, bloqueadaHab, accionesPisosAPisos, bloqueadaHabNombre }) {
    const API_URL = 'https://api.grupotel.com/workers.2/';

    const [prevFechaId, setFechaId] = useState('');
    const [idParte, setIdParte] = useState('');

    const [infoHabitacion, setInfoHabitacion] = React.useState(false);

    const [estadoHab, setEstadoHab] = useState(0);
    const [noMolestarHora, setNoMolestarHora] = useState('');
    const [mostrarHoraDialog, setMostrarHoraDialog] = useState(false);
    const [lockHab, setLockHab] = useState(0);


    const [textoBusqueda, setTextoBusqueda] = useState('');
    const [departamentosFiltrados, setDepartamentosFiltrados] = useState([]);


    //Tabs del Dialog de Mostrar informacion de la maquina
    const [tabValue, setTabValue] = useState(0); // Estado para la pestaña activa

    const handleSearchText = (e) => {
        const searchText = e.target.value.toLowerCase();
        setTextoBusqueda(searchText);
        const departamentosEncontrados = departamentosNuevoParte.filter(departamento =>
            departamento.label.toLowerCase().includes(searchText)
        );
        setDepartamentosFiltrados(departamentosEncontrados);
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                setRutaActual({ idDepartamento: "", nombre: "" });
                setPersonName([]);
                listarTodosPartesHabitacion();
                break;
            case 1:
                setRutaActual({ idDepartamento: "", nombre: "" });
                setPersonName([]);
                activarEdicion();
                break;
            case 2:
                verAmenities();
                break;
            case 3:
                break;
        }
    };

    const [listaPartesRoom, setListaPartesRoom] = useState([]);
    //Listado de Fotos
    const [listaFotosRoom, setListaFotosRoom] = useState([]);
    //Listado de PDF
    const [listaPdfRoom, setListaPdfRoom] = useState([]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    //Listado de Amenities
    const [listaAmenities, setListaAmenities] = useState([]);
    //Registo / Historico Amenities
    const [historicoAmenities, setHistoricoAmenities] = useState([]);

    //Cantidades Amenities
    const [cantidades, setCantidades] = useState({});
    //En caso de querer modificar un Amenity
    const [fechaAmenity, setFechaAmenity] = useState('');
    const [estadoEditar, setEstadoEditar] = useState(0);

    //Conjunto para mostrar un Alert con el resultado de la petición
    const [mensajeError, setMensajeError] = useState('');
    const [tipoError, setTipoError] = useState('');
    const [contadorError, setContadorError] = useState(1);
    const [estadoAlert, setEstadoAlert] = React.useState(false);

    const [errorObservacionesPisos, setErrorObservacionesPisos] = React.useState(false);
    const [errorDepartamentosPisos, setErrorDepartamentosPisos] = React.useState(false);

    const fd = new FormData();
    const requestOptions = {
        method: 'POST',
        body: fd
    };
    const mostrarError = (mensajeError, tipoError) => {
        setMensajeError(mensajeError);
        if (tipoError) {
            setTipoError(tipoError);
        } else {
            setTipoError("error");
        }
        setContadorError(contadorError + 1);
        setEstadoAlert(true);
        const timer2 = setTimeout(() => {
            setEstadoAlert(false);
        }, 2000);
        return () => clearTimeout(timer2);
    };

    //Funcion para actualizar el componente Pisos
    const recargarPisos = () => {
        accionesPisosAPisos(true);//Llamamos a la funcion de Pisos y le pasamos nuetro boolean
        const timer2 = setTimeout(() => {
            accionesPisosAPisos(false);//Para evitar bucles infinitos. 
        }, 500);
        return () => clearTimeout(timer2);
    }

    //Funcion para obtener toda la información de una habitacion.
    const buscarHabitacion = () => {
        if (prevFechaId !== fechaId) {
            if (idHab !== "") {
                setInfoHabitacion(true);
                listarTodosPartesHabitacion();
                // getAmenities();
                setFechaId(fechaId);
            }
        }
    };

    //Funcion para detectar si se pulsa el Enter en el Textfield de Habitacion.
    const comprobarEnterHab = (e) => {
        if (e.key == 'Enter') {
            comprobarHabitacion();
        }
    };

    const comprobarHabitacion = () => {
        const datos = datosEditarParte;
        if (datos.id_hotel != '' && datos.hab != '' && datos.hab != undefined && datos.id_hotel != undefined) {
            fd.append("f", "habitaciones.get");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_hab", datos.hab);
            fd.append("id_hotel", datos.id_hotel);
            // let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result) {
                        let infoPartes = result;
                        setDatosEditarParte({ ...datosEditarParte, "bloque": infoPartes.bloque, "planta": infoPartes.planta })
                    }
                })//En caso de error mostramos nuestro mensaje
                .catch(() => {
                    mostrarError("No existe la habitación: " + datos.hab);
                    console.log("No existe la habitación: " + datos.hab);
                })
        } else if (datos.hab == '' && datos.hab != undefined) {
            mostrarError("No ha seleccionado la habitación. ");
            console.log("No ha seleccionado la habitación. ");
        }
    };

    const toggleLock = () => {
        fd.append("f", "habitaciones.toggleRoomLock");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", idHotel);
        fd.append("id_hab", idHab);
        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then((result) => {
                let error = result.error;
                if (error.id === 0) {
                    ocultarHabitacion();
                } else {
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                mostrarError("Error de la Api al modificar el estado de la habitacion.");
                console.log("Error de la Api al modificar el estado de la habitacion.");
            })
    }

    // Función para controlar los cambios de Estado de las habitaciones
    const checkHab = async (ev) => {
        let estado = ev.target.value;

        if (estado === '3') {
            mostrarLateCheckoutDialog();
        } else {
            setNoMolestarHora('');
            await updateEstadoHab(estado, null);
            setEstadoHab(estado);
        }
    };

    const updateEstadoHab = async (estado, horaCheckOut) => {
        const fd = new FormData();
        fd.append("f", "habitaciones.setEstado");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", idHotel);
        fd.append("id_hab", idHab);
        fd.append("estado", estado);

        if (horaCheckOut) {
            fd.append("horaCheckOut", horaCheckOut);
        } else {
            fd.append("horaCheckOut", null);
        }

        const requestOptions = {
            method: 'POST',
            body: fd
        };

        try {
            const response = await fetch(API_URL, requestOptions);
            const result = await response.json();
            const error = result.error;
            if (error.id === 0) {
                ocultarHabitacion();
            } else {
                mostrarError(error.msg);
                console.log(error.msg);
            }
        } catch {
            mostrarError("Error de la Api al modificar el estado de la habitacion.");
            console.log("Error de la Api al modificar el estado de la habitacion.");
        }
    };

    const mostrarLateCheckoutDialog = () => {
        setMostrarHoraDialog(true);
    };
    const ocultarLateCheckoutDialog = () => {
        setMostrarHoraDialog(false);
        setNoMolestarHora('');
    };

    const confirmHoraClick = async () => {
        if (noMolestarHora) {
            await updateEstadoHab(2, noMolestarHora);
            setMostrarHoraDialog(false);
            setEstadoHab('3');
        }
    };

    const handleHoraChange = (event) => {
        setNoMolestarHora(event.target.value);
    };


    const listarTodosPartesHabitacion = () => {
        //Peticion para obtener todos los partes de la habitacion 
        fd.append("f", "partes.getByRoom");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", idHotel);
        fd.append("hab", idHab);

        fetch(API_URL, requestOptions)
            .then((response) => {
                return response.json()
            })
            .then((result) => {
                console.log(result.horaCheckout)
                let error = result.error;
                if (error.id === 0) {
                    delete result.error;
                    setListaPartesRoom(result.partes);
                    if (result.horaCheckout) {
                        setEstadoHab(3); // El segundo argumento (10) es para indicar que se trata de una base decimal.
                        setNoMolestarHora(result.horaCheckout);
                    } else {
                        setEstadoHab(result.estadoHab) // El segundo argumento (10) es para indicar que se trata de una base decimal.
                        setNoMolestarHora('');
                    }

                    //console.log(result);
                    //console.log(result.estadoHab);
                } else {
                    //console.log(result);
                    //console.log(result.estadoHab);
                    setListaPartesRoom('');
                    if (result.horaCheckout) {
                        setEstadoHab(3); // El segundo argumento (10) es para indicar que se trata de una base decimal.
                        setNoMolestarHora(result.horaCheckout);
                    } else {
                        setEstadoHab(parseInt(result.estadoHab, 10)); // El segundo argumento (10) es para indicar que se trata de una base decimal.
                        setNoMolestarHora('');
                    }
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                mostrarError("Error Api. Obtener los partes de una habitacion.");
                console.log("Error Api. Obtener los partes de una habitacion.");
            })
    };

    function obtenerNombreEstado() {
        <div className='bloqueEstadoHab'>
            <TextField
                variant="outlined"
                margin="normal"
                label="Estado"
                name="estadoHab"
                size="small"
                disabled={estadoHab === '5'}
                value={estadoHab}
                onChange={checkHab}
                select
            >
                <MenuItem value='0'>Pendiente</MenuItem>
                <MenuItem value='1'>Limpia</MenuItem>
                <MenuItem value='2'>No molestar</MenuItem>
                {estadoHab == '6' || estadoHab == '7' ?
                    noMolestarHora === '' ?
                        <MenuItem value='3' >
                            Late Checkout
                        </MenuItem>
                        :
                        <MenuItem value='3'>
                            Late Checkout {noMolestarHora}
                        </MenuItem>

                    : ""
                }
                <MenuItem disabled value='5'>Bloqueada</MenuItem>
                <MenuItem disabled value='6'>Salida</MenuItem>
                <MenuItem disabled value='7'>Sale Hoy</MenuItem>
            </TextField>
        </div>
    }

    //Boolean para mostrar los historicos del parte que le indiquemos.
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const abrirHistorial = (clickedIndex) => {
        if (isOpenCollapse === clickedIndex) {
            setIsOpenCollapse(null);
        } else {
            setIsOpenCollapse(clickedIndex);
        }
    };
    //********Funcionalidad para mostrar o Ocultar los Dialog************* */
    //Funcion para ocultar el dialog.
    const comprobarESCHabitacion = (event) => {
        ocultarHabitacion();
        setTabValue(0);
    }
    //Funcion para volver a ocultar el Dialog de habitacion
    const ocultarHabitacion = () => {
        setErrorDepartamentosPisos(false);
        setErrorObservacionesPisos(false);
        setDatosNuevoParte("");
        setListaImagenesNuevoParte([]);
        setListaPDFNuevoParte([]);
        setListaPartesRoom('');
        setEstadoHab(0);
        setInfoHabitacion(false);
        setTabValue(0);
        setCantidades([]);
        setEstadoEditar(0);
        setFechaAmenity('');
        setHistoricoAmenities([]);
        setListaAmenities([]);
        setRutaActual({ idDepartamento: "", nombre: "" });
        setPersonName([]);
        setDatosEditarParte([estadoInicial]);
        //setMensajeError("");
        recargarPisos();
    };

    const activarEdicion = () => {
        // console.log("Evento Edicion")
        //Conseguir el listado de Departamentos del Hotel.
        buscarDepartamentos(idHotel);
    }

    const verAmenities = () => {
        // console.log("Evento Amenaties")
        getAmenities();
    }
    /**************************************************************/
    //  		Inicio Departamentos
    /**************************************************************/
    //Array que contiene el resultado de getLocationsList. Obtiene un listado de todos los departamentos.
    const [todosDepartamentos] = useState([]);
    //Array temporal para comprobar si hay departamentos con el mismo idPadre.
    const [departamentosListado] = useState([]);
    //Array utilizada para mostrar los departamentos, en Crear Parte.
    const [departamentosNuevoParte] = useState([]);


    //Conjunto de listas donde guardamos un string de los id de Departamentos a los que damos click. Y lista de los nombres de departamentos.
    const [rutaActual, setRutaActual] = useState({ idDepartamento: '', nombre: '' });
    //Variable que utilizamos como contador. Para saber en que posición estamos de los departamentos.
    const [posicionActual, setPosicionActual] = useState(0);
    //Boolean utilizado para habilitar o deshabilitar el boton de atras en Nuevo Parte. Hace referencia a los departamentos.
    const [activarAtras, setActivarAtras] = useState(true);

    //Funcion onChange del Dialog. Busca todos los departamentos de un hotel.
    const buscarDepartamentos = (id_hotel) => {
        if (id_hotel !== 0) {

            fd.append("f", "partes.getLocationsList");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_hotel", id_hotel);
            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let error = result.error;
                    if (error.id === 0) {
                        //console.log(result)
                        let departamentosLista = result;
                        //Funcion para limpiar las listas.
                        vaciarDepartamentos();
                        //Guardamos todos los departamentos.
                        todosDepartamentos.push(departamentosLista);
                        //LLamamos a la funcion para mostrar los departamentos padre con idPadre 0
                        obtenerDepartamento(0);
                    } else {
                        mostrarError(error.msg);
                        console.log(error.msg);
                    }
                })
                .catch(() => {
                    setMensajeError("No se ha podido obtener departamentos.");
                    setContadorError(contadorError + 1);
                    setTipoError("error");
                    setEstadoAlert(true);
                    const timer2 = setTimeout(() => {
                        setEstadoAlert(false);
                    }, 1000);
                    return () => clearTimeout(timer2);
                })
        }
    };

    //Obtiene el id del Padre y mapea todos los departamentos con ese mismo idPadre.
    const obtenerDepartamento = (idPadre, textoPadre, back) => {

        let departamentosLista = todosDepartamentos[0];
        departamentosListado.length = 0;

        //Comprobar y obtener los que tengan el idPadre
        Object.keys(departamentosLista).map((index, i) => {
            if (index !== 'error') {
                var departamento = Object.values(departamentosLista)[i];
                // if (departamento.id_padre === idPadre) { //No sirve hacer directamente el ===  
                if (parseInt(departamento.id_padre) === parseInt(idPadre)) { // Para realizar la comprobación de igualdad estricta. Tenemos que asegurarnos de que sean iguales.
                    departamentosListado.push({ value: departamento.id_lugar, label: departamento.nombre, padre: departamento.id_padre });
                }
            }
            return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
        })

        if (departamentosListado.length !== 0) {
            // departamentosListado.sort((a, b) => (a.label > b.label) ? 1 : -1);
            departamentosListado.sort((a, b) => {
                // Manejar el caso en el que no se proporciona un índice
                if (a.indice === undefined && b.indice === undefined) {
                    // Si ambos elementos no tienen índice, ordenar alfabéticamente por nombre
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                } else if (a.indice !== undefined && b.indice !== undefined) {
                    // Si ambos elementos tienen índice definido, ordenar por índice
                    return a.indice - b.indice;
                } else {
                    // Si uno tiene índice definido y el otro no, el que tenga índice definido debe ir primero
                    return a.indice !== undefined ? -1 : 1;
                }
            });

            departamentosNuevoParte.length = 0;
            departamentosNuevoParte.push(...departamentosListado);
            //Si es Departamento Padre inicializamos la posicion.
            if (idPadre === 0) {
                setPosicionActual(1);
            } else {
                //Si es un sub departamento guardamos la posicion.
                setPosicionActual(posicionActual + 1);
            }
        }
        //En el caso de no ser un back .
        if (back === undefined) {
            //Caso de un Sub departamento.
            //Si tenemos id del Departamento y la posicionActual no es 0
            if (rutaActual.idDepartamento !== '' && posicionActual >= 1) {
                var listaRutas = rutaActual.idDepartamento.split(",");
                setActivarAtras(false);
                //Funcion para cambiar el contenido del ultimo id y nombre.
                const actualizarUltimo = () => {
                    let rutaId = rutaActual.idDepartamento.split(",");
                    rutaId[listaRutas.length - 1] = idPadre;
                    let rutaDepartamento = rutaActual.nombre.split("-");
                    rutaDepartamento[listaRutas.length - 1] = textoPadre;
                    setRutaActual({ idDepartamento: rutaId.toString(), nombre: rutaDepartamento.join("-") });
                }
                //Si el ultimo id es igual a idPadre. Y estamos en la misma posicion reemplazamos el contenido.
                if (listaRutas[listaRutas.length - 1] === idPadre && posicionActual === listaRutas.length) {
                    actualizarUltimo();
                } else {
                    //Caso de que en la misma posicion se ha introducido un id distinto.
                    //Se compara que estemos en la misma posicion,que el tamaño del array. Y si la ultima posicion tiene un id diferente lo reemplazamos.
                    if (posicionActual === listaRutas.length && listaRutas[listaRutas.length - 1] !== idPadre) {
                        actualizarUltimo();
                    } else {
                        //Si la posicion es la misma, pero el id es distinto añdimos le nuevo valor.
                        let rutaId = rutaActual.idDepartamento + "," + idPadre;
                        let rutaDepartamento = rutaActual.nombre + "-" + textoPadre;
                        setRutaActual({ idDepartamento: rutaId, nombre: rutaDepartamento });
                    }
                }
            } else {
                //Caso inicial de idPadre 0
                if (textoPadre !== undefined) {
                    setActivarAtras(false);
                    setRutaActual({ idDepartamento: idPadre, nombre: textoPadre });
                }
            }
        }
    };

    //Obtenemos el id del padre que puede venir del div o el span.
    const obtenerSubDepartamento = (event) => {
        // Si nos llega un onclick del texto. span obtenemos el contenido
        let id = event.target.parentElement.id;
        let texto = event.target.innerText;
        //Si no era un span el id estara vacio. En este caso obtenemos el id del div
        if (id === '') {
            id = event.target.id;
        }
        //Mostramos la flecha para volver atras si es un sub departamento.
        document.querySelector('.botonAtras').classList.remove('ocultar');

        if (id !== undefined) {
            obtenerDepartamento(id, texto);
            setErrorDepartamentosPisos(false);
        }
    };

    const vaciarDepartamentos = () => {
        todosDepartamentos.length = 0;
        rutaActual.idDepartamento = "";
        rutaActual.nombre = 'Nuevo parte';
        setPosicionActual(0);
        setRutaActual({ idDepartamento: "", nombre: "" });
        departamentosNuevoParte.length = 0;
    };

    //Al realizar un cambio en un Textfield. Se guardara la información en un constructor.
    const cambioNuevoParte = (event) => {
        if (event.target.name === "observaciones" && event.target.value.trim() !== "") {
            setErrorObservacionesPisos(false);
        } else if (event.target.name === "observaciones") {
            setErrorObservacionesPisos(true);
        }
        setDatosNuevoParte({ ...datosNuevoParte, [event.target.name]: event.target.value });
    }

    //Funcion de flecha para volver al departamento anterior.
    const backDepartamento = () => {
        //Obtenemos el penultimo id y texto
        let rutaId = rutaActual.idDepartamento.split(",");

        let rutaDepartamento = rutaActual.nombre.split("-");
        rutaId.pop();
        rutaDepartamento.pop();
        if (rutaId[rutaId.length - 1] !== undefined) {
            //listaRutas[listaRutas.length - 1]
            obtenerDepartamento(rutaId[rutaId.length - 1], rutaDepartamento[rutaDepartamento.length - 1], 1);
            setPosicionActual(rutaId.length + 1);
            setRutaActual({ idDepartamento: rutaId.toString(), nombre: rutaDepartamento.join("-") });
        } else {
            rutaActual.idDepartamento = "";
            rutaActual.nombre = 'Nuevo parte';
            setRutaActual({ idDepartamento: "", nombre: "" });
            obtenerDepartamento(0);
            setActivarAtras(true);
            setErrorDepartamentosPisos(true);

        }
    }
    /******************************************************************/
    //                  Fin Departamentos                             //
    /******************************************************************/


    /**************************************************************/
    //  		Apartado imagenes
    /**************************************************************/
    //Boolean para mostrar o cultar el dialog que contendrá las imagenes del parte seleccionado.
    const [galeriaImagenes, setGaleriaImagenes] = React.useState(false);
    //Posicion en la lista de Imagenes
    const [posImage, setPosImage] = useState(0);

    //Boolean para mostrar o ocultar el dialog de los PDF.
    const [galeriaPdf, setGaleriaPdf] = React.useState(false);

    const verInforme = (id_parte) => {
        let url = "https://api.grupotel.com/workers/index.php?token=workers2&action=getPartePdf&id=";
        let final_url = url + id_parte;
        window.open(final_url, '_blank', 'noreferrer');
    }

    const abrirImagen = (fotos, id_parte) => {
        setListaFotosRoom(fotos);
        setIdParte(id_parte);
        setGaleriaImagenes(true);
    }

    const ocultarGaleria = () => {
        setGaleriaImagenes(false);
        setPosImage(0);
    };
    const abrirPdf = (pdf, id_parte) => {
        //Guardamos el Listado de los Pdf
        setListaPdfRoom(pdf);
        setIdParte(id_parte);
        setGaleriaPdf(true);
    }
    const ocultarPdf = () => {
        setGaleriaPdf(false);
    }
    const mostrarPdf = (pdf) => {
        //Abrimos la imagen en una nueva ventana.
        window.open("https://api.grupotel.com/workers.2/imagenes/parte/" + idParte + "/" + pdf);
    }
    //Funcion para pasar a la imagen anterior
    const prevImagen = () => {
        if (posImage - 1 === -1) {
            setPosImage(listaFotosRoom.length - 1);
        } else {
            setPosImage(posImage - 1);
        }
    };

    //Funcion para pasar a la siguiente imagen
    const nextImagen = () => {
        if (posImage + 1 === listaFotosRoom.length) {
            setPosImage(0);
        } else {
            setPosImage(posImage + 1);
        }
    };
    const abrirVentanaImagen = () => {
        //Obtenemos la información de la imagen que estamos mostrando. Utilizando el ID.
        var pepe = document.getElementById('imagenGaleria');
        //Abrimos la imagen en una nueva ventana.
        window.open(pepe.src);
    };
    /**************************************************************/
    //  	FIN	Apartado imagenes
    /**************************************************************/
    /**************************************************************/
    //  	Creacion Parte
    /**************************************************************/

    // Listas para previsualizar Imagenes o Pdf
    const [listaImagenesNuevoParte, setListaImagenesNuevoParte] = useState([]);
    const [listaPDFNuevoParte, setListaPDFNuevoParte] = useState([]);

    const [datosNuevoParte, setDatosNuevoParte] = useState({
        rama: '',
        titulo: '',
        tipo: '',
        observaciones: ''
    });
    const tipoPartes = [
        { value: '', label: '', },
        { value: 'normal', label: 'Normal', },
        { value: 'No conformidad', label: 'No conformidad', },
        { value: 'objeto perdido', label: 'Objeto Perdido', },
        { value: 'amenity', label: 'Amenity', },
        // { value: 'bloqueo', label: 'Bloqueo', },
    ];

    // Quitar la imagen seleccionada
    const borrarImagenNuevoParte = (id) => {
        setListaImagenesNuevoParte(listaImagenesNuevoParte.filter((foto) => foto.id !== id));
    };
    // Quitar el pdf seleccionado
    const borrarPDFNuevoParte = (id) => {
        setListaPDFNuevoParte(listaPDFNuevoParte.filter((pdf) => pdf.id !== id));
    };
    // Abrir imagen o pdf  en ventanda nueva
    const ventanaImagen = (ruta) => {
        const rutaMostrar = ruta.includes("min_") ? ruta.replace("min_", "") : ruta;
        window.open(rutaMostrar);
    };

    //Función para controlar las imagenes y pdf añadidos
    const selecImage = (event) => {
        const files = event.target.files;

        // Verificar si se seleccionó algún archivo
        if (files.length === 0) {
            // Vaciar las listas de imágenes y PDFs, si no se seleccionó ningún archivo
            setListaImagenesNuevoParte([]);
            setListaPDFNuevoParte([]);
            event.target.value = null;  // Limpiar el input
            return;
        }
        //console.log(files)

        let nuevasImagenes = [];
        let nuevosPDFs = [];

        // Filtrar los archivos de imagen y PDF
        const imagenes = Array.from(files).filter(file => file.type.startsWith('image/'));
        const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');

        //Variable para saber el peso de los archivos.
        let pesoArchivos = 0;

        // Montar el array de imágenes
        imagenes.forEach((file, index) => {
            pesoArchivos += file.size;
            nuevasImagenes.push({
                id: index,
                ruta: URL.createObjectURL(file),
                nombre: file.name,
                archivo: file
            });
        });

        // Montar el array de PDFs
        pdfs.forEach((file, index) => {
            let nombrePdf = "";
            if (file.name.length > 29) {
                nombrePdf = file.name.substring(0, 29) + "...";
            } else {
                nombrePdf = file.name;
            }
            pesoArchivos += file.size;
            nuevosPDFs.push({
                id: index,
                ruta: URL.createObjectURL(file),
                nombre: nombrePdf,
                archivo: file
            });
        });

        //Comprobación de si se supera el maximo de 32
        if (pesoArchivos >= 32000000) {
            console.log("El peso de los archivos es mayor a 32MB: " + pesoArchivos);
            mostrarError("Los archivos superan los 32 MB");
            setListaPDFNuevoParte([]);
            setListaImagenesNuevoParte([]);
            event.target.value = null;  // Limpiar el input
            return;
        }

        setListaImagenesNuevoParte(nuevasImagenes);
        setListaPDFNuevoParte(nuevosPDFs);
    };


    const crearParteHab = () => {
        if (rutaActual.idDepartamento !== '') {
            if (datosNuevoParte.observaciones) {
                datosNuevoParte.rama = rutaActual.idDepartamento;
                datosNuevoParte.titulo = rutaActual.nombre;
                var str = '';
                var id = localStorage.getItem('id');
                str += "id_usuario=" + id + "|id_creador=" + id + "|";
                str += "id_hotel=" + idHotel + "|hab=" + idHab + "|";
                Object.keys(datosNuevoParte).forEach((nombre, i) => {
                    if (nombre === 'tipo' && Object.values(datosNuevoParte)[i] === '') {
                        str += nombre + "=normal|"; // En caso de no recibir un tipo
                    } else {
                        if (Object.values(datosNuevoParte)[i] !== '' && Object.values(datosNuevoParte)[i] !== undefined) {
                            str += nombre + "=" + Object.values(datosNuevoParte)[i] + '|';
                        }
                    }
                });

                fd.append("f", "partes.set");
                fd.append("token", localStorage.getItem('token'));
                fd.append("params", str);


                //Recorremos el listado de Imagenes añadidas al parte.
                Object.values(listaImagenesNuevoParte).forEach((imagen, i) => {
                    fd.append("imagen" + i, imagen.archivo);
                });

                //Recorremos los archivos pdf
                listaPDFNuevoParte.forEach((pdf, i) => {
                    fd.append("pdf" + i, pdf.archivo);
                });

                fetch(API_URL, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.error.id === 0) {
                            ocultarHabitacion();
                        } else {
                            mostrarError(result.error.msg);
                            console.log(result.error.msg);
                        }
                    })

            } else {
                mostrarError("Escriba una observacion.");
                console.log("Escriba una observacion.");
                setErrorObservacionesPisos(true);
                setErrorDepartamentosPisos(false);
            }
        } else {
            if (datosNuevoParte.observaciones) {
                mostrarError("Seleccione un Departamento.");
                console.log("Seleccione un Departamento.");
                setErrorDepartamentosPisos(true);
            } else {
                mostrarError("Seleccione un Departamento. Y escriba la observacion.");
                console.log("Seleccione un Departamento. Y escriba la observacion.");
                //Añadir elemento error en obs.
                setErrorObservacionesPisos(true);
                setErrorDepartamentosPisos(true);

            }
        }
    };

    const estructuraNuevoParte = <div>{
        <>
            <div className='nuevoParteHab'>
                <div className='contenedorNuevoParteHab'>
                    <h3 className='sinMargen'>{rutaActual.nombre ? rutaActual.nombre : 'Seleccione el Departamento'}</h3>
                    <TextField
                        className='obsNuevoParteHab'
                        label="Observaciones"
                        multiline
                        name="observaciones"
                        value={datosNuevoParte.observaciones ? datosNuevoParte.observaciones : ''}
                        onChange={cambioNuevoParte}
                        error={errorObservacionesPisos}
                    />
                    <div className='divImgPdf'>
                        <div >
                            <div >
                                <label htmlFor="upload-files" className='nuevoParteBotonAdjuntar'>
                                    <input
                                        className='ocultar'
                                        accept=".pdf, .jpg, .jpeg, .png"
                                        id="upload-files"
                                        type="file"
                                        onChange={selecImage}
                                        multiple
                                    />
                                    <Button component='span' className='colorGrupo' variant="contained">Adjuntar archivo</Button>
                                </label>
                            </div>
                        </div>
                        <div className='anchoMitad'>
                            <div className='bloqueTipoParte'//className='divPdfTipoParte'
                            >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    label="Tipo parte"
                                    name="tipo"
                                    size="small"
                                    value={datosNuevoParte.tipo ? datosNuevoParte.tipo : ''}
                                    onChange={cambioNuevoParte}
                                    select
                                >
                                    {tipoPartes.map((tipoParte) => (
                                        <MenuItem value={tipoParte.value}>
                                            {tipoParte.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='divPdf'>
                            {listaPDFNuevoParte.length > 0 ?
                                <div>
                                    {listaPDFNuevoParte.map((pdf, j) => {
                                        return (
                                            <div className='' key={pdf.id}>
                                                <Button className='nuevoParteNombrePdf' variant="text" onClick={() => ventanaImagen(pdf.ruta)} >{pdf.nombre ? pdf.nombre : ''}</Button>
                                                <IconButton className='iconX' onClick={() => borrarPDFNuevoParte(pdf.id)}>x</IconButton>
                                            </div>
                                        );
                                    })
                                    }
                                </div>
                                : ''
                            }
                        </div>

                        <div className='containerDiv'>
                            {listaImagenesNuevoParte.length > 0 ?
                                <div className='divImgMaquinasNuevas'>
                                    {listaImagenesNuevoParte.map((foto, j) => {
                                        return (
                                            <div className='divCentrarImagen' key={foto.id}>
                                                <IconButton className='iconoBorrarImg' onClick={() => borrarImagenNuevoParte(foto.id)}>
                                                    <CancelIcon className=' ' />
                                                </IconButton>
                                                <img className="nuevoPartePreviewImagen" src={foto.ruta} onClick={() => ventanaImagen(foto.ruta)} />
                                            </div>
                                        );
                                    })
                                    }
                                </div>
                                : ''
                            }
                        </div>
                    </div>

                </div>
                <Box sx={{ width: '40%', height: '370px' }} className='departamento'  >

                    <List
                        className='botonLista'
                    >
                        <ListItem className={'divisor'}>
                            <div className='itemBoton'>
                                <TextField InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FilterAltIcon color='#00a2dc' />
                                        </InputAdornment>
                                    ),
                                }} value={textoBusqueda} onChange={(ev) => { handleSearchText(ev) }} id="search" fullWidth></TextField>
                            </div>
                        </ListItem>
                        <ListItem divider className='divisor botonAtras'>
                            <ListItemButton className='itemBoton'
                                disabled={activarAtras}
                                onClick={backDepartamento}
                            >
                                <ListItemText className='center'>
                                    <ArrowBackIcon className='icon-button ' />
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        {(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).map((departamento, i) =>
                            <>
                                <ListItem divider className={(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).length - 1 === i ? 'divisor ultimoDiv' : 'divisor'} >
                                    <ListItemButton className='itemBoton' value={departamento.label} id={departamento.value} onClick={(ev) => { obtenerSubDepartamento(ev) }}>
                                        <ListItemText primary={departamento.label} id={departamento.value} className='center' />
                                    </ListItemButton>
                                </ListItem>
                            </>
                        )
                        }
                    </List>
                </Box>
            </div>
        </>
    }</div>

    /**************************************************************/
    //  	FIN	Apartado Crear Parte
    /**************************************************************/
    /**************************************************************/
    //  	Apartado Amenities
    /**************************************************************/
    const getAmenities = () => {

        fd.append("f", "amenities.getAmenities");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", idHotel);
        fd.append("id_hab", idHab);

        fetch(API_URL, requestOptions)
            .then((response) => {
                return response.json()
            })
            .then((result) => {
                let error = result.error;
                if (error.id === 0) {
                    // console.log(result);
                    //Guardamos la información 
                    setListaAmenities(result.listaAmenities);
                    setHistoricoAmenities(result.historicoAmenities);
                } else {
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                mostrarError("Error Api. Obtener los partes de una habitacion.");
                console.log("Error Api. Obtener los partes de una habitacion.");
            })

    }

    //Controlamos los cambios de numeros, para evitar que quede el '0' a la izquierda o solo el '0'
    const cambiarCantidad = (event, id) => {
        let newValue = event.target.value.replace(/\D/g, ''); // Eliminar caracteres no numéricos
        newValue = newValue === '' ? '0' : newValue; // Establecer a '0' si el campo está en blanco
        newValue = newValue.replace(/^0+(?=\d)/, ''); // Eliminar ceros a la izquierda
        const nuevaCantidades = { ...cantidades };
        nuevaCantidades[id] = newValue;
        setCantidades(nuevaCantidades);
    }
    const quitaCero = (e, id) => {
        if (e.target.value === '' || e.target.value === '0' || e.target.value === '00') {
            const nuevaCantidades = { ...cantidades };
            nuevaCantidades[id] = '';
            setCantidades(nuevaCantidades);
        }
    }
    const ponCero = (e, id) => {
        if (e.target.value === '0' || e.target.value === '00') {
            const nuevaCantidades = { ...cantidades };
            nuevaCantidades[id] = '';
            setCantidades(nuevaCantidades);
        }
    }

    const crearAmenity = () => {
        const cantidadesFiltradas = Object.keys(cantidades)
            .filter(key => cantidades[key] !== "" && cantidades[key] !== "0")
            .reduce((obj, key) => {
                obj[key] = cantidades[key];
                return obj;
            }, {});

        if (Object.keys(cantidadesFiltradas).length === 0) {
            mostrarError("No hay cantidades en los Amenaties");
            console.log("No hay cantidades en los Amenaties");
        } else {

            // console.log(cantidades);
            // console.log("Info Amenity |  id_hab: " + idHab + " id_hotel: " + idHotel)
            // console.log(cantidadesFiltradas);

            if (estadoEditar != 0) { //Si es una edición pasamos la fecha para borrar todos los registros de esa habitación con esa fecha.
                fd.append("fecha", fechaAmenity);
                // console.log(fechaAmenity);
            }

            fd.append("f", "amenities.setAmenities");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_hotel", idHotel);
            fd.append("id_hab", idHab);
            fd.append("listaAmenities", JSON.stringify(cantidadesFiltradas));

            fetch(API_URL, requestOptions)
                .then((response) => {
                    return response.json()
                })
                .then((result) => {
                    let error = result.error;
                    if (error.id === 1205) {
                        console.log(result)
                        mostrarError(error.msg, "success");
                        setCantidades([]);//Vaciamos las cantidades
                        getAmenities();//Solicitamos de nuevo la información
                        if (estadoEditar != 0) {
                            setEstadoEditar(0);
                            setFechaAmenity('');
                        }
                    } else {
                        mostrarError(error.msg);
                        console.log(error.msg);
                    }
                })
                .catch(() => {
                    mostrarError("Error Api. Insertar Amenaties");
                    console.log("Error Api. Insertar Amenaties");
                })
        }
    }



    // Booleano para controlar la apertura y cierre del historial de cada fecha
    const [isOpenCollapseAmenity, setIsOpenCollapseAmenity] = useState(null);

    const abrirHistorialAmenity = (clickedFecha) => {
        if (isOpenCollapseAmenity === clickedFecha) {
            setIsOpenCollapseAmenity(null);
        } else {
            setIsOpenCollapseAmenity(clickedFecha);
        }
    };

    const editarAmenities = (historico) => {
        if (estadoEditar === 0) {

            // Obtenemos la fecha (Realmente la extraemos)
            let { fecha } = historico;
            setFechaAmenity(fecha);
            setEstadoEditar(fecha);// Quiza convenga el id parte.

            // Obtenemos los registros y ordenamos
            const registros = historico.registros.reduce((acc, { id, cantidad }) => {
                if (acc[id]) {
                    acc[id] = String(Number(acc[id]) + Number(cantidad));
                } else {
                    acc[id] = cantidad;
                }
                return acc;
            }, {});
            setCantidades(registros);
            // console.log("Fecha de referencia:", fecha);
            // console.log("Registros de Amenities:");
            console.log(registros);

        } else {//Desactivamos la edicion
            setEstadoEditar(0);
            setCantidades([]);
            setFechaAmenity('');
        }
    }

    const estructuraAmenities = <div>{listaAmenities.length > 0 ?
        <div className='divAmenities'>
            <List className='listaAmenities'>
                {Object.values(listaAmenities).sort((a, b) => (a.indice > b.indice) ? 1 : -1).map((amenity, i) =>
                    <>
                        <ListItem divider key={amenity.id} id={amenity.id} className='lineaAmenaties' >
                            {/* <Checkbox className='checkboxAmenities'></Checkbox> */}
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Cantidad"
                                type="text"
                                name="cantidad"
                                size="small"
                                className='cantidadAmenities'
                                onFocus={(e) => estadoEditar === 0 ? quitaCero(e, amenity.id) : ""}
                                onBlur={(e) => estadoEditar === 0 ? ponCero(e, amenity.id) : ""}
                                value={cantidades[amenity.id] || 0 || ''}
                                onChange={(e) => cambiarCantidad(e, amenity.id)}
                            />
                            <ListItemText className='textoAmenities'>
                                {amenity.nombre}
                            </ListItemText>
                        </ListItem>
                    </>
                )
                }
            </List>
            <div className='texto-beneficio'></div>
            <List className='listaAmenities'>
                {Object.values(historicoAmenities).sort((a, b) => (a.indice > b.indice) ? 1 : -1).map((historico, i) => (
                    <div key={historico.fecha}>
                        <ListItemButton
                            className={historico.activo === "1" ? 'lineaAmenaties bordeParte infoParte nuevo-gradient' : 'lineaAmenaties bordeParte infoParte cerrado-gradient'}
                            onClick={() => abrirHistorialAmenity(historico.fecha)}>
                            <HtmlTooltip
                                placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit" onClick={(e) => { e.stopPropagation() }}>{historico.activo === "1" ? "Pendiente" : "Entregado"}</Typography>
                                    </React.Fragment>
                                }
                            >
                                <ListItemText className='infoParteId' >
                                    |
                                </ListItemText>
                            </HtmlTooltip>
                            <ListItemText className='textoAmenities infoParteRoom'>
                                {historico.fecha}
                            </ListItemText>
                            <ListItemText className='textoAmenities infoParteRoom'>
                                {historico.id_parte}
                            </ListItemText>
                            {historico.activo === "1" ? <IconButton className={estadoEditar === historico.fecha ? 'boton pintado' : 'boton '} onClick={(e) => { e.stopPropagation(); editarAmenities(historico) }} >
                                <ModeEditIcon className='icon-button ' />
                            </IconButton> : ""}
                        </ListItemButton>
                        <Collapse in={isOpenCollapseAmenity === historico.fecha} timeout="auto" unmountOnExit className='separarPalabras'>

                            {historico.registros.sort((a, b) => (a.indice > b.indice) ? 1 : -1).map((registro, j) => (
                                <ListItem key={registro.id}
                                //className='infoParteIdHijo'
                                >
                                    <ListItemText primary={registro.nombre} secondary={`Cantidad: ${registro.cantidad}`} className='justificarTexto infoParteRoom infoTitulo' />
                                    <ListItemText className='mostrar justificarTexto infoParteRoom infoHistorico' >{registro.usuario}</ListItemText>
                                    {//<ListItemText>{registro.activo}</ListItemText>
                                    }
                                </ListItem>
                            ))}
                        </Collapse>
                    </div>
                ))}
            </List>
        </div> : "El hotel no tiene Amenities asociados"
    }</div >

    /**************************************************************/
    //  	FIN Apartado Amenities
    /**************************************************************/
    /**************************************************************/
    //      Apartado Editar Parte
    /**************************************************************/

    // Variables para la Edición de Parte
    const [parte, setParte] = React.useState([]);

    // Mostrar el Dialog de Editar Parte
    const [modoLectura, setModoLectura] = React.useState(false);

    //Variable para mostrar la ruta de Lugares/Departamentos del Parte. O contendra un "Seleccionar Lugar/Departamento".
    const [departamentoInicial, setDepartamentoInicial] = useState("");
    //Variable para controlar si se esta mostrando el Listado de Departamentos en Editar Parte. 0 no se muestra. 1 se muestra.  
    const [estadoListadoDepartamentos, setEstadoListadoDepartamentos] = useState(0);

    //Guardar en personName el string ,  de Usuario Asignado.
    const [personName, setPersonName] = useState([]);

    //Listado de los Usuarios con el mismo id Departamento.
    const [usuariosLista, setUsuariosLista] = useState([]);
    //Lista de los proveedores
    const [terceros, setTercerosLista] = useState([]);
    const [mostrarTerceros, setMostrarTerceros] = useState(false);
    //Lista del Tipo de Terceros.
    const [tipoTerceros, setTipoTercerosLista] = useState([]);

    const estadoInicial = [{
        id: '',
        fecha_creacion: '',
        creador: '',
        id_usuario: '',
        usuario_asignado: '',
        fecha_aplazado: '',
        id_hotel: '',
        hotel: '',
        bloque: '',
        planta: '',
        hab: '',
        operacion: '',
        prioridad: '',
        tipo: '',
        departamento: '',
        id_departamento: '',
        titulo: '',
        observaciones: '',
    }]
    const [datosEditarParte, setDatosEditarParte] = useState(estadoInicial);

    //Funcion para obtener los campos del Parte seleccionado.
    const editarTemporal = (idUsuario, datos, historico) => {//Recibimos el IdUsuario conectado. Y el tipo de operacion a realizar en el parte.
        let operacion = "";

        vaciarDepartamentos();
        datosEditarParte.length = 0;
        //Obtenemos la ultima fecha de Aplazado si existe     11-02-2023 08:00:00
        let fecha = "";
        let asignado = "";
        if (historico != undefined && historico != []) {
            fecha = historico[0].fecha_aplazado;
            asignado = historico[0].usuario_asignado;
        }

        //Creamos un array para separar la fecha de la hora   ['11-02-2023', '08:00:00']
        let soloFecha = fecha.split(" ");
        let fechaAplazada;
        //Obtenemos unicamente la fecha y le damos la vuelta para poder imprimir en el Textfield tipo Date. 2023-02-11
        fechaAplazada = soloFecha[0].split("-").reverse().join("-");
        // console.log("Información del parte");
        // console.log(datos);
        //Guardamos en el temporal la ruta de Departamentos/Lugares.
        setDepartamentoInicial(datos.titulo);
        //Obtenemos todos los datos del parte actual.Para mostrar en el Dialog Editar Parte.
        setDatosEditarParte({
            id: datos.id_parte,
            fecha_creacion: datos.fecha_creacion,
            creador: datos.creador,
            id_usuario: idUsuario,
            usuario_asignado: asignado,
            fecha_aplazado: fechaAplazada,
            id_hotel: datos.id_hotel,
            hotel: datos.hotel,
            bloque: datos.bloque,
            planta: datos.planta,
            hab: datos.habitacion,
            operacion: operacion,
            prioridad: datos.ultima_prioridad,
            tipo: datos.tipo,
            departamento: datos.departamento_creador,
            id_departamento: datos.id_departamento,
            titulo: datos.titulo,
            observaciones: ''
        })
    }

    //Funcion para Ocultar el Dialog de edicion del Parte.
    const finEdicion = () => {
        listarTodosPartesHabitacion();
        //Dejamos el datosEditarParte vacio.
        setDatosEditarParte([estadoInicial]);
        setMostrarTerceros(false);
        setListaImagenEditarParte([]);
        setListaPDFEditarParte([]);
        setModoLectura(false);
        document.querySelector('.editar').classList.remove('pintado');
        setEstadoListadoDepartamentos(0);

        //actualizarListaPartes(); // Solicitar de nuevo los partes de la habitación.
    };

    //Funcion para detectar que la tecla pulsada es ESC y ocultar el Dialog de EditarParte.
    const comprobarESCEditarParte = (event) => {
        if (event.keyCode == 27) {
            finEdicion();
        }
    }

    const obtenerParte = (id_parte) => {
        setPersonName([]);
        setDatosEditarParte([estadoInicial]);

        // Obtenemos toda la información del Parte.
        fd.append("f", "partes.get");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id", id_parte);
        // Para poder ver la información de partes de Otros departamentos.
        fd.append("salidas", 1); // En el caso de ser el creador del parte, pero no pertenecer al departamento Destino

        const requestOptions = {
            method: 'POST',
            body: fd
        };
        let datosParte = [];
        let historicosParte = [];
        let fotosParte = [];
        let temp = [];

        fetch(API_URL, requestOptions)
            .then((response) => {
                return response.json()
            })
            .then((result) => {
                let error = result.error;
                if (error.id === 0) {
                    // console.log(" Resultado: ")
                    // console.log(result)
                    datosParte = result.parte.datos;
                    historicosParte = result.parte.historico;
                    fotosParte = result.parte.fotos;
                    temp.push(datosParte);
                    temp.push(historicosParte);
                    temp.push(fotosParte);

                    setParte(temp);
                    let idUsuario = localStorage.getItem('id');
                    editarTemporal(idUsuario, datosParte, historicosParte);

                    obtenerUsuariosDepartamento(datosParte.id_hotel);

                    obtenerListaTerceros();
                    setModoLectura(true);
                    document.querySelector('.editar').classList.add('pintado');
                } else {
                    mostrarError(error.msg);
                    console.log(error.msg);
                }
            })
            .catch(() => {
                mostrarError("Error Api. Obteniendo los datos del Parte");
                console.log("Error Api. Obteniendo los datos del Parte");
            })
    }

    const [controlError, setControlError] = useState(false);

    //Variable donde se guardara la imagen seleccionada en Editar Parte.
    const [listaImagenEditarParte, setListaImagenEditarParte] = useState([]);
    const [listaPDFEditarParte, setListaPDFEditarParte] = useState([]);

    //Cada vez que se realiza un cambio en el Dialog de Editar Parte guardamos la nueva información. onChange
    const editarParte = (event) => {
        // console.log(event.target.name)
        // console.log(event.target.value)

        setDatosEditarParte({
            ...datosEditarParte,
            [event.target.name]: event.target.value
        })
        if (event.target.name == "operacion" && (event.target.value == "terceros" || event.target.value == "cerrado")) {//Si es operacion. Comprobar si el Estado del parte es Terceros. Si es asi mostrar los select de Proveedores.
            setMostrarTerceros(true);
        } else if (event.target.name == "operacion") {//Si se ha realizado otro tipo de cambio en operacion ocultamos. Los proveedores
            if (event.target.value != "") {
                setControlError(false);
            } else {
                setControlError(true);
            }
            setMostrarTerceros(false);
        }

        //Petición de Cristian, si se aplaza modificar el estado del parte a Nuevo.
        if (event.target.name == "fecha_aplazado" && datosEditarParte.operacion != "nuevo") {
            setDatosEditarParte({
                ...datosEditarParte,
                [event.target.name]: event.target.value,
                ['operacion']: 'nuevo'
            })
        }
    }

    //Funcion para editar la información del Parte.
    const confirmarEdicion = () => {
        if (datosEditarParte.operacion != "" && datosEditarParte.operacion != " ") {//Comprobamos que hayan seleccionado una Operacion/Estado del Parte. "Nuevo/Asignado,etc..."
            setControlError(false);
            modificarParte();
        } else {
            setControlError(true);
            mostrarError("Seleccione un Estado al Parte.");
            console.log("Seleccione un Estado al Parte.");
        }
    };

    //Función para realizar la modificación del parte con la información obtenida del formulario Editar Parte
    const modificarParte = () => {
        var str = '';

        //Comprobar los Usuarios asignados del Chip.
        //Si no tiene datos tenemos que introducir el usuario actual.
        if (personName.length >= 1) {//Comprobamos si hay asignado 1 usuario.
            //Creamos un array de los id de usuarios asignados en el chip.
            let usuarios = personName.map(function (usuario) {
                return usuario.value;
            });

            //Creamos un string con los id de usuarios seleccionados en el chip. Si solo hay uno obtenemos su id.
            let asignados = "";

            //? para verificar si historico[0] existe y tiene la propiedad usuario_asignado
            let id_asignado = ""//Si el ultimo historico tenia a una persona asignada lo obtenemos.
            if (parte.historicosParte != undefined && parte.historicosParte != []) {
                id_asignado = parte.historicosParte[0]?.id_asignado;
            }

            //Comprobamos si el primer usuario asignado sigue estando en el array del chip.
            if (usuarios.includes(id_asignado)) {
                //Obtenemos el indice del usuario asignado.
                const index = usuarios.findIndex(persona => persona === id_asignado);

                //Si no esta en la primera posición.
                if (index !== 0) {//Ponemos al usuario asignado el primero de la lista.
                    usuarios.splice(index, 1); // Elimina el elemento encontrado
                    usuarios.unshift(datosEditarParte.id_usuario); // Añade el elemento al inicio del array
                }
                asignados = usuarios.join(",");
                str += "id_usuario=" + usuarios[0] + "|";
                str += "id_usuarios=" + asignados + "|";
            } else {
                asignados = usuarios.join(",");

                str += "id_usuario=" + usuarios[0] + "|";
                str += "id_usuarios=" + asignados + "|";
            }
        } else {//Añadimos el usuario actual a los usuarios asignados.
            str += "id_usuarios=" + localStorage.getItem('id') + "|";
            str += "id_usuario=" + localStorage.getItem('id') + "|";
        }
        let operacion = '';
        //Creamos y establecemos el formato de los parametros que vamos a enviar.
        Object.keys(datosEditarParte).forEach((nombre, i) => {
            let paramValue = Object.values(datosEditarParte)[i];
            if (nombre != 'id_usuario') {
                //Comprobamos que el campo no sea la Fecha para aplazar el parte.
                if (nombre != 'fecha_aplazado') {
                    if (Object.values(datosEditarParte)[i] != '' && Object.values(datosEditarParte)[i] != undefined) {
                        if (nombre === 'observaciones') {
                            let observacionesValue = Object.values(datosEditarParte)[i].replace(/\|/g, ' ');
                            str += nombre + "=" + observacionesValue + "|";
                        } else if (nombre === 'operacion') {
                            operacion = paramValue
                            str += nombre + "=" + paramValue + "|";
                        } else {
                            str += nombre + "=" + paramValue + "|";
                        }
                    }
                } else {
                    //En el caso de ser Fecha Aplazada creamos un array para obtener la fecha
                    let soloFecha = Object.values(datosEditarParte)[i].split(" ");
                    //Comprobamos que la fecha no este vacia o venga con el valor 0000
                    if (Object.values(datosEditarParte)[i] != "" && soloFecha[0] != "0000-00-00") {
                        //Si la fecha viene con datos, le añadimos la hora 08:00
                        str += nombre + "=" + Object.values(datosEditarParte)[i] + " 08:00:00|";
                    } else {
                        //Si la fecha vuelve vacia, le indicamos que su valor sera NULL
                        str += nombre + "=NULL|";
                    }
                }
            }
        });

        //En el caso de modificar la ruta Lugar/Departamento 
        if (rutaActual.idDepartamento !== 0 && rutaActual.idDepartamento !== null && rutaActual.idDepartamento !== "") {
            modificarTituloParte();
        }

        const fd = new FormData();
        const requestOptions = {
            method: 'POST',
            body: fd
        };
        fd.append("f", "partes.set");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", datosEditarParte.id_hotel);
        fd.append("params", str); //Parametros Obligatorios: 'id_usuario', 'operacion', 'prioridad'. Tambien el id del Parte.
        //Recorremos el listado de Imagenes para añadirlas
        Object.values(listaImagenEditarParte).forEach((imagen, i) => {
            fd.append("imagen" + i, imagen.archivo);
        });

        //Recorremos los archivos pdf
        listaPDFEditarParte.forEach((pdf, i) => {
            fd.append("pdf" + i, pdf.archivo);
        });

        // let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if (result.error.id !== 0) { //En caso de error.
                    mostrarError("Se ha actualizado el parte.", "success");

                } else {
                    mostrarError(result.error.msg);
                    console.log(result.error.msg);
                }
            })
            .catch(() => {
                mostrarError("No se ha actualizado el parte." + parte);
                console.log("No se ha actualizado el parte." + parte);
            })
        // listarTodosPartesHabitacion();

        finEdicion();
    }

    //Funcion para realizar el cambio de Titulo del Parte seleccionado.
    const modificarTituloParte = () => {
        const fd = new FormData();
        const requestOptions = {
            method: 'POST',
            body: fd
        };
        fd.append("f", "partes.setNewLugar");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id", datosEditarParte.id);//Id del Parte
        fd.append("rama", rutaActual.idDepartamento);//Ruta del nuevo Lugar del Parte   ej:4,107,109
        // let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.error.id !== 0) { //En caso de error.
                    mostrarError(result.error.msg);
                    console.log(result.error.msg);
                }
                //console.log("Se ha modificado el Titulo del Parte.");
            })
            .catch(() => {
                mostrarError("Error Api. No se ha podido modificar el Titulo del Parte");
                console.log("Error Api. No se ha podido modificar el Titulo del Parte");
            })
    }


    //Funcion para inicializar y mostrar los departamentos o Ocultarlos.
    const mostrarDepartamentos = () => {
        if (estadoListadoDepartamentos == 0) {
            setActivarAtras(true);
            //Buscamos todos los departamentos relacionados con el Hotel.
            buscarDepartamentos(datosEditarParte.id_hotel);
            setDepartamentoInicial("Seleccione un Lugar/Departamento");
            //Mostrar el boton para volver atras en un departamento
            document.querySelector('.botonAtras').classList.remove('ocultar');
            //Mostar el listado de Departamentos.
            document.querySelector('.departamentoEditar').classList.remove('ocultar');
            //Guardamos el 1 para indicar que se esta mostrando el Listado de Departamentos en Editar Parte.
            setEstadoListadoDepartamentos(1);
        } else {
            //Guardamos en el temporal la ruta de Departamentos/Lugares.
            setDepartamentoInicial(datosEditarParte.titulo);
            //Mostrar el boton para volver atras en un departamento
            document.querySelector('.botonAtras').classList.add('ocultar');
            //Mostar el listado de Departamentos.
            document.querySelector('.departamentoEditar').classList.add('ocultar');
            //Guardamos el 0 para indicar que se esta ocultando el Listado de Departamentos en Editar Parte.
            setEstadoListadoDepartamentos(0);
        }
    };

    const obtenerUsuariosDepartamento = (hotel_id) => {
        let temp = [];
        fd.append("f", "usuarios.getList");
        fd.append("token", localStorage.getItem('token'));
        let str = "";
        str = "id_hotel=" + hotel_id + "|id_departamento=" + localStorage.getItem('id_departamento') + '|';
        fd.append("params", str);

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                delete result.error;

                //Recorremos el array de Usuarios para obtener sus id de Usuario y nombres.
                Object.keys(result.usuarios).map((id, i) => {
                    var nombreUsuario = Object.values(result.usuarios)[i].nombre;
                    //temp.push([id,nombreUsuario]);
                    temp.push({ value: id, label: nombreUsuario });
                })
                //Guardamos el listado de Usuarios para el Select de Editar Parte.
                setUsuariosLista(temp);
            })
            .catch(() => {
                mostrarError("No se ha podido listar los Usuarios.");
                console.log("No se ha podido listar los Usuarios.");
            })
    }

    /**************************************************************/
    //  		 Proveedores
    /**************************************************************/
    //Obtener el listado de Terceros. Los Proveedores.
    const obtenerListaTerceros = () => {
        fd.append("f", "proveedores.getList");
        fd.append("token", localStorage.getItem('token'));
        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;
                if (error.id == 0) {
                    let listaTerceros = [];
                    let tipoTerceros = [];
                    listaTerceros.push({ value: "", label: "" });
                    Object.values(result.proveedores).map((index, i) => {
                        listaTerceros.push({ value: index['cif'], label: index['nombre_comercial'] });
                    })
                    setTercerosLista(listaTerceros);

                    tipoTerceros.push({ value: "", label: "" });
                    Object.values(result.tipo_terceros).map((tipo, i) => {
                        tipoTerceros.push({ value: Object.keys(result.tipo_terceros)[i], label: tipo });
                    })

                    setTipoTercerosLista(tipoTerceros);
                }
            })
            .catch(() => {
                mostrarError("No se ha podido obtener los Terceros.");
                console.log("No se ha podido obtener los Terceros.")
            })
    }

    const cambioProveedor = (event) => {
        //Si se ha realizado un cambio en el autocomplete de Proveedores. 
        //Primero comprobamos si podemos obtener un valor del index.
        if (event.target.dataset.optionIndex) {
            //console.log(event.target.innerText);//Obtenemos el Nombre del Proveedor
            //Obtenemos el valor del cif seleccionado usando la posicion del index.
            let cif = "";
            Object.values(terceros).map((index, i) => {
                if (index.label == event.target.innerText) {
                    cif = index.value;
                }
            })
            setDatosEditarParte({
                ...datosEditarParte,
                "cif_proveedor": cif, "nombre_comercial": event.target.innerText
            })
        } else if (event.target.dataset.testid == "CloseIcon") {
            //En caso contrario se habra realizado click en la X para borrar el contenido de Proveedores
            setDatosEditarParte({
                ...datosEditarParte,
                "cif_proveedor": "", "nombre_comercial": ""
            })
        }
    };

    //Listas utilizadas
    const tipoPrioridad = [
        { value: 'baja', label: 'Baja', },
        { value: 'media', label: 'Media', },
        { value: 'alta', label: 'Alta', },
    ];

    const tipoEstadosDefault = [
        { value: ' ', label: ' ' },
        { value: 'nuevo', label: 'Nuevo' },
        { value: 'asignado', label: 'Asignado' },
        { value: 'en ejecucion', label: 'En ejecución' },
        { value: 'terceros', label: 'Terceros' },
        { value: 'cerrado', label: 'Cerrado' },
        { value: 'anulado', label: 'Anulado' },
    ];

    const tipoEstadosAnulado = [
        { value: ' ', label: ' ' },
        { value: 'anulado', label: 'Anulado' },
    ];

    const obtenerSelectEstados = () => {
        let listaEstados = tipoEstadosDefault;
        let departamento = localStorage.getItem('id_departamento');
        if (departamento != datosEditarParte.id_departamento) {
            listaEstados = tipoEstadosAnulado;
        }

        return (
            <TextField
                className='proveedoresTextfield'
                variant="outlined"
                margin="normal"
                label="Estado"
                name="operacion"
                id="tipoOperacion"
                error={controlError}
                onChange={editarParte}
                size="small"
                value={datosEditarParte.operacion ? datosEditarParte.operacion : ' '}
                select
            >
                {listaEstados.map((tipoEstado) => (
                    <MenuItem key={`editEstadoDatosP-${tipoEstado.value}`} value={tipoEstado.value}>
                        {tipoEstado.label}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    //Funcion para el select chip multiple
    const chipMultipleAsignados = (idChip, lectura) => {

        const modificarAsignados = (id, nombre) => {
            // Comprobar si ya existe el usuario.
            const index = personName.findIndex(persona => persona.value === id);
            if (index !== -1) {
                // El id ya está. Procedemos a borrarlo.
                //Primero creamos una copia de el array actual.
                const copiaPersonName = JSON.parse(JSON.stringify(personName));
                copiaPersonName.splice(index, 1);//Quitamos el indice de la array para borrar el usuario.
                setPersonName(copiaPersonName);
            } else {
                //El id no esta en nuestra array de Usuarios.
                const nuevoAsignado = { value: id, label: nombre };
                setPersonName([...personName, nuevoAsignado]);
            }
        }

        return (
            <FormControl fullWidth id={"select-chip-asignados-control-datosParte" + idChip}>
                <InputLabel id={"select-chip-asignados-label-datosParte" + idChip} >Asignados</InputLabel>

                <Select
                    labelId={"select-chip-asignados-label-datosParte" + idChip}
                    //id={"select-chip-asignados" + idChip}
                    multiple
                    //label="Asignados"
                    //value={personName}
                    value={(personName != [] && personName.length >= 1) ? personName : ['']}
                    size="small"
                    inputProps={lectura == true ? { readOnly: true, } : { readOnly: false, }}
                    input={<OutlinedInput id={"select-multiple-chip-datosParte" + idChip} label="Asignados" />}
                    renderValue={() => (
                        <Box
                            className="chipNombres"
                            size="small"
                        >
                            {personName != [] && personName.length >= 1 ?
                                personName.map((persona) => (
                                    <Chip key={`asignado-datosParte-${persona.value}`} label={persona.label} className={'grupotel-blue'} />
                                ))
                                : <Chip key={`asignado-datosParte-0`} label={' '} />}
                        </Box>
                    )}
                >
                    {usuariosLista != "" ?
                        usuariosLista.map((usuario) => (
                            <MenuItem key={`mostrarUsuarios-${usuario.value}`} value={usuario.value} onClick={() => modificarAsignados(usuario.value, usuario.label)} className={personName.find(persona => persona.value === usuario.value) ? 'grupotel-blue' : ''} >
                                {usuario.label}
                            </MenuItem>
                        ))
                        : null
                    }
                </Select>
            </FormControl>
        );
    };
    /**************************************************************/
    //  		Apartado imagenes Editar Parte
    /**************************************************************/
    //Función para obtener y actualizar la imagen del parte. Afecta a el Dialog de EditarParte.
    const selecImageEditarParte = (event) => {
        const files = event.target.files;

        // Verificar si se seleccionó algún archivo
        if (files.length === 0) {
            // Vaciar las listas de imágenes y PDFs, si no se seleccionó ningún archivo
            setListaImagenEditarParte([]);
            setListaPDFEditarParte([]);
            event.target.value = null;  // Limpiar el input
            return;
        }

        let nuevasImagenes = [];
        let nuevosPDFs = [];

        // Filtrar los archivos de imagen y PDF
        const imagenes = Array.from(files).filter(file => file.type.startsWith('image/'));
        const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');

        //Variable para saber el peso de los archivos.
        let pesoArchivos = 0;

        // Montar el array de imágenes
        imagenes.forEach((file, index) => {
            pesoArchivos += file.size;
            nuevasImagenes.push({
                id: index,
                ruta: URL.createObjectURL(file),
                nombre: file.name,
                archivo: file
            });
        });

        // Montar el array de PDFs
        pdfs.forEach((file, index) => {
            let nombrePdf = "";
            if (file.name.length > 29) {
                nombrePdf = file.name.substring(0, 29) + "...";
            } else {
                nombrePdf = file.name;
            }
            pesoArchivos += file.size;
            nuevosPDFs.push({
                id: index,
                ruta: URL.createObjectURL(file),
                nombre: nombrePdf,
                archivo: file
            });
        });

        //Comprobación de si se supera el maximo de 32
        if (pesoArchivos >= 32000000) {
            mostrarError("Los archivos superan los 32 MB");
            console.log("Los archivos superan los 32 MB");
            setListaPDFEditarParte([]);
            setListaImagenEditarParte([]);
            event.target.value = null;  // Limpiar el input
            return;
        }

        setListaImagenEditarParte(nuevasImagenes);
        setListaPDFEditarParte(nuevosPDFs);
    };

    // Abrir imagen o pdf  en ventanda nueva
    const ventanaImagenEditarParte = (ruta) => {
        const rutaMostrar = ruta.includes("min_") ? ruta.replace("min_", "") : ruta;
        window.open(rutaMostrar);
    };

    const borrarImagenEditarParte = (id) => {
        setListaImagenEditarParte(listaImagenEditarParte.filter((foto) => foto.id !== id));
    }
    const borrarPdfEditarParte = (id) => {
        setListaPDFEditarParte(listaPDFEditarParte.filter((pdf) => pdf.id !== id));
    }



    useEffect(() => {
        // Comprobamos que el mensaje sea diferente al último mostrado.
        buscarHabitacion();
    });

    if (listaPartesRoom !== undefined) {

        const listaHistoricos = (fotos, parteId) => {
            let pdfHistorico = [];
            let fotoHistorico = [];

            if (fotos) {
                fotos.map((foto, j) => {
                    let extension = foto.archivo.split(".");
                    if (extension[extension.length - 1] === "pdf") {//Caso de que la extension es un pdf
                        pdfHistorico.push(foto);
                    } else {//Si no es un pdf entonces sera una imagen.
                        fotoHistorico.push(foto);
                    }
                    return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
                })
                return <><div className=' divImagen'>
                    {pdfHistorico.length >= 1 ? <IconButton className='boton boton-peque' onClick={(e) => { e.stopPropagation(); abrirPdf(pdfHistorico, parteId) }} >
                        <PictureAsPdfIcon className='icon-button ' />
                    </IconButton> : ""}
                </div>
                    <div className=' divImagen'>
                        {fotoHistorico.length >= 1 ? <IconButton className='boton boton-peque' onClick={(e) => { e.stopPropagation(); abrirImagen(fotoHistorico, parteId) }} >
                            <ImageSearchIcon className='icon-button ' />
                        </IconButton> : ""}
                    </div>
                </>
            }
        }

        //Ordenamos por el index utilizando el sort. Ya que la respuesta del fetch al pasar a json nos ordena el resultado por el id de Parte.
        const listaPartes = Object.values(listaPartesRoom).sort((a, b) => (a.index > b.index) ? 1 : -1).map((parte, i) =>
            <>
                <ListItemButton onClick={() => abrirHistorial(parte.id)} key={parte.id} id={parte.id} className={parte.operacion === 'en ejecucion' ? 'en-ejecucion-gradient bordeParte infoParte' : parte.operacion + "-gradient bordeParte infoParte"}>
                    <HtmlTooltip

                        placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                        title={
                            <React.Fragment>
                                <Typography color="inherit" onClick={(e) => { e.stopPropagation() }}>{parte.id}</Typography>
                            </React.Fragment>
                        }
                    >
                        <ListItemText className='infoParteId' >
                            |
                        </ListItemText>
                    </HtmlTooltip>
                    <ListItemText className='infoParteRoom infoTitulo' primary={parte.titulo} />
                    <ListItemText className='infoParteRoom infoHistorico' primary={parte.fecha} />
                    <div className='infoParteRoom centrarInfoParteRoom usuarioImagen' >

                        <ListItemText primary={parte.creador} onClick={() => abrirHistorial(parte.id)} />

                    </div>

                    <div className='infoParteRoom centrarInfoParteRoom usuarioImagenLeft' >
                        {/* renderizar si tienes permisos */}
                        {
                            parte.id_departamento == localStorage.getItem('id_departamento') || parte.id_creador == localStorage.getItem('id') ?
                                <div className=' divImagen'>
                                    <IconButton className='boton boton-peque editar' onClick={(e) => { e.stopPropagation(); obtenerParte(parte.id) }} >
                                        <ModeEditIcon className='icon-button ' />
                                    </IconButton>
                                </div> : ""
                        }
                        {//console.log(parte)
                        }

                        {parte.fotos ?
                            listaHistoricos(parte.fotos, parte.id)
                            : ""
                        }
                        <div className=' divImagen'>
                            <IconButton className='boton boton-peque ' onClick={(e) => { e.stopPropagation(); verInforme(parte.id) }} >
                                <PrintIcon className='icon-button ' />
                            </IconButton>
                        </div>

                    </div>



                </ListItemButton>
                <Collapse in={isOpenCollapse === parte.id} timeout="auto" unmountOnExit className='separarPalabras'>
                    {parte.historico ?
                        < div >
                            {parte.historico.map((historico, j) => {
                                return < div className={historico.estado === 'en ejecucion' ? 'en-ejecucion  infoParteIdHijo' : historico.estado + "-gradient infoParteIdHijo"}>
                                    <ListItem >
                                        <HtmlTooltip
                                            placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">{historico.estado}</Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <ListItemText className='infoParteId'>
                                                |
                                            </ListItemText>
                                        </HtmlTooltip>

                                        <ListItemText className='mostrar justificarTexto infoParteRoom infoTitulo' >
                                            {historico.fecha_aplazado !== '' ?
                                                historico.estado === 'terceros' ?
                                                    '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
                                                    : '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + historico.observaciones

                                                : historico.estado === 'terceros' ?
                                                    '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
                                                    : historico.observaciones
                                            }
                                        </ListItemText>
                                        <ListItemText className='mostrar justificarTexto infoParteRoom infoHistorico' >
                                            {historico.fecha_evento}
                                        </ListItemText>
                                        <div className='infoParteRoom usuarioHistorico' >
                                            {historico.usuario_asignado}
                                        </div>
                                    </ListItem>
                                </div>
                            })
                            }</div>
                        : ""
                    }
                </Collapse>
            </>
        );

        return (
            <div>
                <Dialog fullScreen={fullScreen}
                    open={infoHabitacion}
                    onClose={comprobarESCHabitacion}
                    aria-labelledby="responsive-dialog-title" id="dialogoFiltros">
                    <DialogTitle className='tituloHabitacion'>{idHab ? "Habitacion: " + idHab : "Seleccione una habitacion"}
                        {/* <p >Estado: {obtenerNombreEstado(estadoHab)}</p> */}
                        {/* {
                        <TextField
                            variant="outlined"
                            margin="normal"
                            label="Estado"
                            name="estadoHab"
                            className='anchoTextField'
                            size="small"
                            disabled={estadoHab === 5 ? true : false}
                            value={estadoHab}
                            onChange={checkHab}
                            select
                        >
                            <MenuItem value='0'>Pendiente</MenuItem>
                            <MenuItem value='1'>Limpia</MenuItem>
                            <MenuItem value='2'>No molestar <AlarmAddOutlinedIcon></AlarmAddOutlinedIcon></MenuItem>
                            <MenuItem disabled value='5'>Bloqueada</MenuItem>
                            <MenuItem disabled value='6'>Salida</MenuItem>
                            <MenuItem disabled value='7'>Sale Hoy</MenuItem>
                        </TextField> */
                        }
                        <div className='bloqueEstadoHab'>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Estado"
                                name="estadoHab"
                                size="small"
                                disabled={estadoHab === '5'}
                                value={estadoHab}
                                onChange={checkHab}
                                select
                            >
                                <MenuItem value='0'>Pendiente</MenuItem>
                                <MenuItem value='1'>Limpia</MenuItem>
                                <MenuItem value='2'>No molestar</MenuItem>
                                <MenuItem disabled value='3'>Late Checkout {noMolestarHora}</MenuItem>
                                {estadoHab === '6' || estadoHab === '7' ?

                                    <MenuItem value='3'>
                                        Late Checkout {noMolestarHora}
                                    </MenuItem>

                                    : ""
                                }

                                <MenuItem disabled value='5'>Bloqueada</MenuItem>
                                <MenuItem disabled value='6'>Salida</MenuItem>
                                <MenuItem disabled value='7'>Sale Hoy</MenuItem>
                            </TextField>

                        </div>
                        <Button className="colorBoton" onClick={toggleLock} >{bloqueadaHab === true ? <LockOpenOutlinedIcon></LockOpenOutlinedIcon> : <LockOutlinedIcon></LockOutlinedIcon>}</Button>                        {/* {estadoHab === 5 ? <Button disabled className="disabled" onClick={checkHab} >Check Hab</Button> : <Button className="colorGrupo" onClick={checkHab} >Check Hab</Button>} */}
                        {
                            bloqueadaHabNombre ?
                                bloqueadaHab ?
                                    <span>Último bloqueo: {bloqueadaHabNombre}</span>
                                    :
                                    <span>Último desbloqueo: {bloqueadaHabNombre}</span>
                                : ''
                        }
                    </DialogTitle>
                    <Tabs value={tabValue} onChange={handleChangeTab}>
                        <Tab label="Lista Partes" />
                        <Tab label="Nuevo Parte" />
                        <Tab label="Nuevo Amenities" />
                    </Tabs>
                    <Divider></Divider>
                    <DialogContent className='container-filtros alturaDivHab'>
                        {tabValue === 0 && (
                            <div className='infoPartesHab'>
                                <List>
                                    {listaPartes}
                                </List>
                            </div>
                        )}
                        {tabValue === 1 && (
                            <div className='editarHab'>
                                {estructuraNuevoParte}
                            </div>
                        )}
                        {tabValue === 2 && (
                            <div>{estructuraAmenities}</div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                            {
                                tabValue == 1 ?
                                    <div>
                                        <Button className="colorGrupo" onClick={ocultarHabitacion}>Cancelar</Button>
                                        <Button className="colorGrupo" onClick={crearParteHab}>Confirmar</Button>
                                    </div> : tabValue == 2 ?
                                        <div>
                                            <Button className="colorGrupo" onClick={ocultarHabitacion}>Cancelar</Button>
                                            <Button className="colorGrupo" onClick={crearAmenity}>Confirmar</Button>
                                        </div> :
                                        <Button className="colorGrupo" onClick={ocultarHabitacion}>Cerrar</Button>
                            }

                        </ButtonGroup>
                    </DialogActions>
                    {
                        estadoAlert ?
                            <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
                            : ""
                    }
                </Dialog>

                {listaFotosRoom.length > 0 ?
                    <Dialog fullScreen={fullScreen}
                        open={galeriaImagenes}
                        onClose={ocultarGaleria}
                        aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
                    >
                        <DialogTitle>Galeria fotos Parte {idParte}</DialogTitle>
                        <DialogContent className='container-filtros '>
                            {
                                listaFotosRoom ?
                                    listaFotosRoom[posImage].archivo !== undefined || listaFotosRoom[posImage].archivo !== "" ?
                                        <div>
                                            <IconButton component="span" className='iconoFoto buscador' onClick={abrirVentanaImagen} >
                                                <AddAPhotoIcon className='icon-button ' />
                                            </IconButton>

                                            <img id="imagenGaleria" className="mdc-image-list__image"
                                                alt={"Imagen del Parte " + idParte + " Nombre archivo: " + listaFotosRoom[posImage].archivo}
                                                src={"https://api.grupotel.com/workers.2/imagenes/parte/" + idParte + "/" + listaFotosRoom[posImage].archivo}
                                                style={{ "width": "auto" }} onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = "https://api.grupotel.com/workers/imagenes/partes/" + idParte + "/" + listaFotosRoom[posImage].archivo;
                                                }} />

                                        </div>
                                        : ""
                                    : ""
                            }

                        </DialogContent>
                        <DialogActions className='centrar'>
                            {
                                listaFotosRoom.length > 1 ?
                                    <div className='direccion ' id='direccionImagen'>
                                        <ButtonGroup className=" colorGrupo" variant="contained" aria-label="outlined button group">
                                            <IconButton component="span" className='boton direccionFlecha ' onClick={prevImagen}>
                                                <WestOutlinedIcon />
                                            </IconButton>
                                            <Button className="colorGrupo2" onClick={ocultarGaleria}>Cerrar</Button>
                                            <IconButton component="span" className='boton direccionFlecha ' onClick={nextImagen}>
                                                <EastOutlinedIcon />
                                            </IconButton>
                                        </ButtonGroup>
                                        <p>{posImage + 1} / {listaFotosRoom.length}</p>
                                    </div>
                                    :
                                    <ButtonGroup variant="contained" aria-label="outlined button group">
                                        <Button className="colorGrupo" onClick={ocultarGaleria}>Cerrar</Button>
                                    </ButtonGroup>
                            }
                        </DialogActions>
                    </Dialog>
                    : ""}
                {listaPdfRoom.length > 0 ?
                    <Dialog fullScreen={fullScreen}
                        open={galeriaPdf}
                        onClose={ocultarPdf}
                        aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
                    >
                        <DialogTitle>Listado Pdf Parte: {idParte}</DialogTitle>
                        <DialogContent className='container-filtros '>
                            {
                                listaPdfRoom ?
                                    listaPdfRoom.map((pdf, i) => {
                                        return <ListItemButton onClick={() => mostrarPdf(listaPdfRoom[i].archivo)} >{listaPdfRoom[i] ? listaPdfRoom[i].archivo : ''}</ListItemButton>
                                    })
                                    : ""
                            }
                        </DialogContent>
                        <DialogActions className='centrar'>
                            <ButtonGroup variant="contained" aria-label="outlined button group">
                                <Button className="colorGrupo" onClick={ocultarPdf}>Cerrar</Button>
                            </ButtonGroup>
                        </DialogActions>
                    </Dialog>
                    : ""
                }

                <Dialog
                    fullScreen={fullScreen}
                    open={mostrarHoraDialog}
                    onClose={ocultarLateCheckoutDialog}
                    aria-labelledby="responsive-dialog-title" id="configLateCheckout" style={{ "width": "100%", "height": "100%" }}
                >
                    <DialogTitle>Configurar hora de Late Checkout</DialogTitle>
                    <DialogContent >
                        <div className='divFiltrosTextField centrarContenido'>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Hora"
                                type="time"
                                size="small"
                                value={noMolestarHora}
                                onChange={handleHoraChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions className='centrar'>
                        <ButtonGroup variant="contained" aria-label="outlined button group">
                            <Button className="colorGrupo" onClick={ocultarLateCheckoutDialog}>Cerrar</Button>
                            <Button className="colorGrupo" onClick={confirmHoraClick}>Confirmar</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Dialog>

                <Dialog fullScreen={fullScreen}
                    open={modoLectura}
                    onClose={comprobarESCEditarParte}
                    aria-labelledby="responsive-dialog-title" id="dialogDatosParteEditar" >
                    <DialogTitle>{rutaActual.idDepartamento ? (datosEditarParte.id ? datosEditarParte.id + ' - ' : '') + rutaActual.nombre : (datosEditarParte.id ? datosEditarParte.id + ' - ' : '') + departamentoInicial}</DialogTitle>
                    <DialogContent className='container-filtros alturaDialog'>
                        <form>
                            <div className='bloque-inputs'>
                                <TextField
                                    className='medio'
                                    variant="outlined"
                                    margin="normal"
                                    label="Bloque"
                                    name='bloque'
                                    size="small"
                                    value={datosEditarParte.bloque ? datosEditarParte.bloque : ''}
                                    onChange={editarParte}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                                <TextField
                                    className='numeros'
                                    variant="outlined"
                                    margin="normal"
                                    label="Planta"
                                    name='planta'
                                    onChange={editarParte}
                                    size="small"
                                    value={datosEditarParte.planta ? (datosEditarParte.planta ? datosEditarParte.planta : ' ') : ' '}
                                    //type="number"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                                <TextField
                                    key="editarParteHab"
                                    variant="outlined"
                                    margin="normal"
                                    label="Hab."
                                    name='hab'
                                    size="small"
                                    className='habitacionBuscador'
                                    onChange={editarParte}
                                    onKeyPress={(ev) => { comprobarEnterHab(ev) }}
                                    value={datosEditarParte.hab ? datosEditarParte.hab : ''}
                                    InputProps={
                                        {
                                            endAdornment:
                                                <IconButton
                                                    key="buscarHab"
                                                    className="boton buscador botonHabitacionBuscador"
                                                    // onClick={buscarHabitacion}
                                                    onClick={() => buscarHabitacion()}
                                                >
                                                    <SearchIcon className='icon-button ' />
                                                </IconButton>
                                        }}
                                />

                                <TextField
                                    className='medio'
                                    variant="outlined"
                                    margin="normal"
                                    label="Tipo parte"
                                    name="tipo"
                                    size="small"
                                    onChange={editarParte}
                                    value={datosEditarParte.tipo ? datosEditarParte.tipo : 'normal'}
                                    select
                                >
                                    {tipoPartes.map((tipoParte) => (
                                        <MenuItem key={`editTipoDatosP-${tipoParte.value}`} value={tipoParte.value}>
                                            {tipoParte.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    label="Aplazar"
                                    type="date"
                                    format={'DD/MM/YYYY'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name='fecha_aplazado'
                                    size="small"
                                    value={datosEditarParte.fecha_aplazado ? datosEditarParte.fecha_aplazado : ''}
                                    onChange={editarParte}
                                />
                            </div>
                            <div className='formChip' >
                                {chipMultipleAsignados(2, false)}
                            </div>
                            <div className='bloque-inputs alinearIzquierda'>
                                <div className='divEdicion'>
                                    <div className='divFiltrosTerceros'>
                                        {obtenerSelectEstados()}
                                        <TextField
                                            className='proveedoresTextfield'
                                            variant="outlined"
                                            margin="normal"
                                            label="Prioridad"
                                            name="prioridad"
                                            onChange={editarParte}
                                            size="small"
                                            value={datosEditarParte.prioridad ? datosEditarParte.prioridad : 'baja'}
                                            select
                                        >
                                            {tipoPrioridad.map((prioridad) => (
                                                <MenuItem key={`editPrioridadDatosP-${prioridad.value}`} value={prioridad.value}>
                                                    {prioridad.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className='divFiltrosTerceros'>
                                        {mostrarTerceros ?
                                            <div className='divFiltrosTextField'>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    label="Tipo terceros"
                                                    name="id_tipo_terceros"
                                                    className='proveedoresTextfield'
                                                    size="small"
                                                    value={datosEditarParte.id_tipo_terceros ? datosEditarParte.id_tipo_terceros : ''}
                                                    onChange={editarParte}
                                                    select
                                                >
                                                    {tipoTerceros.map((tipoTercero) => (
                                                        <MenuItem key={`editTerceroDatosP-${tipoTercero.value}`} value={tipoTercero.value}>
                                                            {tipoTercero.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <Autocomplete
                                                    id="proveedorAuto"
                                                    className='proveedoresTextfield'
                                                    options={terceros}
                                                    onChange={cambioProveedor}
                                                    size="small"
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    renderInput={(params) => <TextField {...params} label="Proveedor" />}
                                                />
                                            </div>
                                            : ""
                                        }
                                    </div>
                                    <TextField
                                        className='observaciones observacionesEditarParte'
                                        label="Observaciones"
                                        name="observaciones"
                                        size="small"
                                        onChange={editarParte}
                                        multiline
                                        rows={12}
                                        value={datosEditarParte.observaciones ? datosEditarParte.observaciones : ' '}
                                    />
                                </div>
                                <div className='containerBotonesEditarDepartamento'>
                                    <div className='containerPdf'>
                                        <label htmlFor="upload-files" className=''>
                                            <input
                                                className='ocultar'
                                                accept=".pdf, .jpg, .jpeg, .png"
                                                id="upload-files"
                                                type="file"
                                                onChange={selecImageEditarParte}
                                                multiple
                                            />
                                            <Button component='span' className='colorGrupo botonEditarDepartamento' variant="outlined">Añadir archivo</Button>
                                        </label>
                                        <Button
                                            variant="outlined"
                                            margin="normal"
                                            className='colorGrupo botonEditarDepartamento'
                                            onClick={mostrarDepartamentos}>
                                            Cambiar Lugar
                                        </Button>
                                    </div>
                                    <div className='css-1rv3ei8-MuiFormControl-root-MuiTextField-root'>


                                        <div className='divPdf'>
                                            {listaPDFEditarParte.length > 0 ?
                                                <div className='containerPdf' >
                                                    {listaPDFEditarParte.map((pdf, j) => {
                                                        return (
                                                            <div className='' key={pdf.id}>
                                                                <Button className='nuevoParteNombrePdf' variant="text" onClick={() => ventanaImagenEditarParte(pdf.ruta)} >{pdf.nombre ? pdf.nombre : ''}</Button>
                                                                <IconButton className='iconX' onClick={() => borrarPdfEditarParte(pdf.id)}>x</IconButton>
                                                            </div>
                                                        );
                                                    })
                                                    }
                                                </div>
                                                : ''
                                            }
                                        </div>
                                        <div className='containerDiv'>
                                            {listaImagenEditarParte.length > 0 ?
                                                <div className='divImgMaquinasNuevas'>
                                                    {listaImagenEditarParte.map((foto, j) => {
                                                        return (
                                                            <div className='divCentrarImagen' key={foto.id}>
                                                                <IconButton className='iconoBorrarImg' onClick={() => borrarImagenEditarParte(foto.id)}>
                                                                    <CancelIcon className=' ' />
                                                                </IconButton>
                                                                <img className="editarPartePreviewImagen" src={foto.ruta} onClick={() => ventanaImagenEditarParte(foto.ruta)} />
                                                            </div>
                                                        );
                                                    })
                                                    }
                                                </div>
                                                : ''
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className='nuevoParteBloque  '>
                                    <div className=' bloqueEdicion departamentoEditar ocultar anchoDepartamentos'>
                                        <Box sx={{ width: '100%' }} className='departamento listaDepartamentos'>

                                            <List
                                                className='botonLista'
                                                sx={{ width: '100% !important' }}
                                            >
                                                <ListItem className={'divisor'}>
                                                    <div className='itemBoton'>
                                                        <TextField InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FilterAltIcon color='#00a2dc' />
                                                                </InputAdornment>
                                                            ),
                                                        }} value={textoBusqueda} onChange={(ev) => { handleSearchText(ev) }} id="search" fullWidth></TextField>
                                                    </div>
                                                </ListItem>
                                                <ListItem divider className='divisor botonAtras'>
                                                    <ListItemButton className='itemBoton'
                                                        disabled={activarAtras}
                                                        onClick={backDepartamento}
                                                    >
                                                        <ListItemText className='center'>
                                                            <ArrowBackIcon className='icon-button ' />
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                                {(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).map((departamento, i) =>
                                                    <>
                                                        <ListItem divider className={(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).length - 1 === i ? 'divisor ultimoDiv' : 'divisor'} >
                                                            <ListItemButton className='itemBoton' value={departamento.label} id={departamento.value} onClick={(ev) => { obtenerSubDepartamento(ev) }}>
                                                                <ListItemText primary={departamento.label} id={departamento.value} className='center' />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </>
                                                )
                                                }
                                            </List>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                            <Button className="colorGrupo" onClick={finEdicion}>Cancelar</Button>
                            <Button className="colorGrupo" onClick={confirmarEdicion}>Confirmar</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Dialog>
            </div >
        )
    } else {//En caso de no tener partes.
        return (
            <div>
                <Dialog fullScreen={fullScreen}
                    open={infoHabitacion}
                    onClose={comprobarESCHabitacion}
                    aria-labelledby="responsive-dialog-title" id="dialogoFiltros">
                    <DialogTitle className='tituloHabitacion'>{idHab ? "Habitacion: " + idHab : "Seleccione una habitacion"}
                        {/* <p >Estado: {obtenerNombreEstado(estadoHab)}</p> */}
                        <Button className="colorGrupo" onClick={checkHab} >Check Hab</Button>
                        <Button className="colorGrupo" onClick={(ev) => { activarEdicion(ev) }}>Nuevo Parte</Button>
                    </DialogTitle>
                    <Divider></Divider>
                    <DialogContent className='container-filtros'>
                        {tabValue === 0 && (
                            <div className='infoPartesHab'>
                                <List>
                                    {"No hay partes"}
                                </List>
                            </div>
                        )}
                        {tabValue === 1 && (
                            <div className='editarHab'>
                                {estructuraNuevoParte}
                            </div>
                        )}
                        {tabValue === 2 && (
                            <div>{estructuraAmenities}</div>
                        )}

                    </DialogContent>
                    <DialogActions>
                        <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                            {
                                tabValue == 1 ?
                                    <div>
                                        <Button className="colorGrupo" onClick={ocultarHabitacion}>Cancelar</Button>
                                        <Button className="colorGrupo" onClick={crearParteHab}>Confirmar</Button>
                                    </div> : tabValue == 2 ?
                                        <div>
                                            <Button className="colorGrupo" onClick={ocultarHabitacion}>Cancelar</Button>
                                            <Button className="colorGrupo" onClick={crearAmenity}>Confirmar</Button>
                                        </div>
                                        : <Button className="colorGrupo" onClick={ocultarHabitacion}>Cerrar</Button>
                            }
                        </ButtonGroup>
                    </DialogActions>
                    {
                        estadoAlert ?
                            <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
                            : ""
                    }
                </Dialog>
            </div >
        )
    }
}
export default AccionesPisos;